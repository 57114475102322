import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface CredentialState {
    login: string;
    password: string;
}

export const initialState: CredentialState = {
    login: '',
    password: ''
};

const credentialSlice = createSlice({
    name: 'credentials',
    initialState,
    reducers: {
        setLogin: (state: CredentialState, action: PayloadAction<string>) => {
            state.login = action.payload;
        }
    }
});

export const { setLogin } = credentialSlice.actions;

export default credentialSlice.reducer;