import React, { useEffect } from 'react';
import AppView from './AppView';

import {BrowserRouter, Route, Routes} from "react-router-dom";
import EnterCredentialsScreen from "src/components/screens/EnterCredentialsScreen";
import ForgotPasswordScreen from "src/components/screens/ForgotPasswordScreen";
import EnterBadgeScreen from "src/components/screens/EnterBadgeScreen";
import WelcomeScreen from "src/components/preHires/WelcomeScreen";
import OtpDestinationScreen from "src/components/preHires/OtpDestinationScreen";
import DownloadAppScreen from "src/components/preHires/DownloadAppScreen";
import EnterTokenAndPasswordScreen from "src/components/preHires/EnterTokenAndPasswordScreen";
import EnterOtpScreen from "src/components/preHires/EnterOtpScreen";
import CreatePasswordScreen from "src/components/preHires/CreatePasswordScreen";
import { Provider } from 'react-redux';
import store from 'src/redux/store';
import ReactRoutes from 'src/constants/react-routes';
import IdPRoutes from 'src/constants/idp-routes';
import { saveState } from "src/util/localStorage";
import { throttle } from "lodash";
import initializeAmplify from 'src/analytics/amplify';
import { Helmet } from 'react-helmet';
import TranslationKeys from 'src/constants/translation-keys';
import { useTranslation } from 'react-i18next';
import 'src/assets/js/omniture.js';

function App() {
    const { t } = useTranslation();

    // Saves the states to session storage on state change
    store.subscribe(throttle(() => {
        saveState({
            welcomeInfo: store.getState().welcomeInfo,
            otp: {
                ...store.getState().otp,
                enteredOtp: '', // we don't want to store these values in session storage
                otpLengthCheck: undefined,
            }
        });
    }, 1000));

    useEffect(() => {
        initializeAmplify();
    }, []);

    return (
        <Provider store={store}>
            <Helmet>
                <title>{t(TranslationKeys.UI.TITLE, { '0': 'Amazon A to Z' })}</title>
            </Helmet>
            <BrowserRouter>
                <Routes>
                    <Route path={ReactRoutes.ROOT} element={<AppView />}>
                        <Route path={ReactRoutes.ENTER_CREDENTIALS} element={<EnterCredentialsScreen />} />
                        <Route path={ReactRoutes.RESET_PASSWORD} element={<ForgotPasswordScreen />} />
                        <Route path={IdPRoutes.ENTER_BADGE} element={<EnterBadgeScreen />} />
                        <Route path={ReactRoutes.PREHIRES_WELCOME} element={<WelcomeScreen/>} />
                        <Route path={ReactRoutes.PREHIRES_OTP_DESTINATION} element={<OtpDestinationScreen/>} />
                        <Route path={ReactRoutes.PREHIRES_DOWNLOAD_APP} element={<DownloadAppScreen/>} />
                        <Route path={ReactRoutes.PREHIRES_TOKEN_AND_PASSWORD} element={<EnterTokenAndPasswordScreen/>} />
                        <Route path={ReactRoutes.PREHIRES_ENTER_OTP} element={<EnterOtpScreen/>} />
                        <Route path={ReactRoutes.PREHIRES_CREATE_PASSWORD} element={<CreatePasswordScreen/>} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
