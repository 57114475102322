import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface APIContextState {
    csrfToken: string;
}

export const initialState = {
    csrfToken: ''
};

const APIContextSlice = createSlice({
    name: 'api-context',
    initialState,
    reducers: {
        setCsrfToken: (state: APIContextState, action: PayloadAction<string>) => {
            state.csrfToken = action.payload;
        }
    }
});

export const { setCsrfToken } = APIContextSlice.actions;

export default APIContextSlice.reducer;