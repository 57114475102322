import React, { useEffect } from 'react';
import APIHelper from 'src/helpers/api-helper';
import { Outlet } from "react-router";
import { useAppDispatch} from "src/redux/hooks";
import { setContext } from 'src/redux/context-slice';
import ApiError from "src/errors/api-error";
import i18n from 'i18next';
import StateInitializationHelper from "src/helpers/state-initialization-helper";
import {useStore} from "react-redux";
import CookieHelper from 'src/helpers/cookie-helper';
import CookieNames from 'src/constants/cookie-names';
import QueryParams from 'src/constants/query-params';
import { setCsrfToken } from "src/redux/api-context-slice";
import { useSearchParams } from 'react-router-dom';
import { useBasePath } from 'src/hooks/useBasePath';
import { isIdPRoute } from 'src/util/helpers';
import IdPHelper from 'src/helpers/idp-helper';

// This react hook should be treated as the "root" for all components
export default function AppView() {
    const dispatch = useAppDispatch();
    const store = useStore();
    let [ searchParams ] = useSearchParams();
    const basePath = useBasePath();

    useEffect(() => {
        const init = searchParams.get(QueryParams.INIT);
        if (init === 'true') {
            const csrfToken = searchParams.get(QueryParams.CSRF_TOKEN);
            if (csrfToken) {
                store.dispatch(setCsrfToken(csrfToken));
            }

            const sessionId = searchParams.get(QueryParams.SESSION_ID);
            const transactionId = searchParams.get(QueryParams.TRANSACTION_ID);
            IdPHelper.initIdPContext({
                transactionId: transactionId,
                sessionId: sessionId
            })
            .then()
            .catch(e => {
                // TODO: Handle cases when the user fails to initialize PassportV1 context.
                // This must be done before letting large amounts of Prod traffic hit React.
                // Should we just erase and restart the user's session?
                console.error(e);
            });
        }
    }, [searchParams]);

    // On first initialization, we need to initialize the state based on query params
    useEffect(() => {
        StateInitializationHelper.initializeState(location, store);
    }, []);

    // Initialize our app state with the context data we'll need everywhere else
    useEffect(() => {
        // There should not be a need to set the user context for `/idp/api` routes
        // for React Migration. There might be a case for it in the future. But for now,
        // there is not a need to fire unnecessary extra calls.
        if (isIdPRoute(basePath)) {
            return;
        }

        APIHelper.getIdPUserContext().then(r => {
            dispatch(setContext(r));
            // When language cookie not present, set the locale to whatever the locale is set as in the response
            // here, since that should be the user's preferred language.
            if (!CookieHelper.getCookieValue(CookieNames.PREFERRED_LANGUAGE)) {
                i18n.changeLanguage(r.locale.replace('_', '-'));
            }
        }).catch((e : ApiError) => {
            // TODO: This represents a pretty serious error because there's a lot of data we need here, determine
            //  how best to respond here.
            console.error("Failed to fetch context.", e)
        });
    }, []);

    return (
        <Outlet />
    );
}
