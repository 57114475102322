import React from "react";
import { useTranslation } from "react-i18next";
import icon from 'src/images/cancel-login.png';
import TranslationKeys from "src/constants/translation-keys";
import { redirectToAtoZWebProd } from "src/util/helpers";
import OmnitureHelper from "src/helpers/omniture-helper";

export default function KioskCancelLogin() {
    const { t } = useTranslation();

    function handleOnClick(event: React.MouseEvent<HTMLDivElement>): void {
        event.preventDefault();
        // This is actually being ignored for Kiosk devices.
        // I will leave it here as a guidance for other pages and components.
        // TODO: Delete the metric emission here once we add the session storage key logic or maybe consider emitting it?
        OmnitureHelper.trackOmnitureLinkClick('Cancel active login');
        // TODO: Redirect to AtoZ until we migrate the enter-password page and set the storage key there.
        // Crash the app if the key not found. This is the same behavior as our current PassportV1.
        redirectToAtoZWebProd();
    }

    return (
        <div id="cancelLogin" className="cancel-login" onClick={handleOnClick}>
            <div>
                <img src={icon} alt={t(TranslationKeys.UI.BADGE.CANCEL)} />
            </div>
            <div className="cancel-login-text">
                {t(TranslationKeys.UI.BADGE.CANCEL)}
            </div>
        </div>
    );
}
