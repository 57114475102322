import React, { useEffect } from 'react';
import { View, Row} from '@amzn/stencil-react-components/layout';
import Footer from './Footer';
import OtpDestinationContentView from './OtpDestinationContentView';
import { getLogger } from "src/logger/logger";
import { METRIC_NAME } from "src/logger/loggerTypes";
import { COMPONENT_EVENT } from "src/logger/loggerConstants";
import { useAppSelector } from "src/redux/hooks";

function OtpDestinationScreen() {
    const login: string = useAppSelector(state => state.welcomeInfo.login);
    const logger = getLogger();
    useEffect(() => {
    logger.info(`${COMPONENT_EVENT.PREHIRE_OTP_PAGE.SCREEN_LOADING}`, { username: login });
    }, []);
    return (
        <div className="fullScreen">
            <div className="row-flex-container">
                <div data-testid="OtpImageBackground" className="flex-box-left-badge-background">
                </div>
                <div className="flex-box-right">
                    <div data-testid="OtpContent" className="content-wrap">
                        <OtpDestinationContentView/>
                    </div>
                    <div data-testid="footer" className="footer-container">
                        <Footer/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OtpDestinationScreen;