import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PreHireFaqState {
    visible: boolean
}

export const initialState: PreHireFaqState = {
    visible: false
}

const preHireFaqSlice = createSlice({
    name: 'preHireFaq',
    initialState,
    reducers: {
        setVisible: (state: PreHireFaqState, action: PayloadAction<boolean>) => {
            state.visible = action.payload
        }
    }
})

export const { setVisible } = preHireFaqSlice.actions;

export default preHireFaqSlice.reducer;
