import { combineReducers, configureStore } from '@reduxjs/toolkit'
import type { PreloadedState } from '@reduxjs/toolkit'
import credentials from './credential-slice';
import contexts from './context-slice';
import apiContext from './api-context-slice';
import welcomeInfo from './pre-hire/welcome-info-slice';
import otp from './pre-hire/otp-slice'
import passwordInput from './pre-hire/password-slice'
import createPasswordState from "src/redux/pre-hire/create-password-slice";
import preHireFaq from "src/redux/pre-hire/pre-hire-faq-slice";
import { loadState } from "src/util/localStorage";

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
    credentials,
    contexts,
    welcomeInfo,
    otp,
    passwordInput,
    createPasswordState,
    preHireFaq,
    apiContext
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState
    });
}

// Gets the preloaded state from sessions storage
const preLoadedState = loadState();

const store = configureStore({ reducer: rootReducer , preloadedState: preLoadedState});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

export default store;
