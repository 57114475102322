import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WelcomeInfoState {
    login: string,
    preferredName: string,
    passwordEventId: string,
}

export const initialState: WelcomeInfoState = {
    login: '',
    preferredName: '',
    passwordEventId: ''
}

const welcomeInfoSlice = createSlice({
    name: 'welcome-info',
    initialState,
    reducers: {
        setLogin: (state: WelcomeInfoState, action: PayloadAction<string>) => {
            state.login = action.payload;
        },
        setPreferredName: (state: WelcomeInfoState, action: PayloadAction<string>) => {
            state.preferredName = action.payload;
        },
        setPasswordEventId: (state: WelcomeInfoState, action: PayloadAction<string>) => {
            state.passwordEventId = action.payload;
        }
    }
});

export const { setLogin, setPreferredName, setPasswordEventId } = welcomeInfoSlice.actions;

export default welcomeInfoSlice.reducer;
