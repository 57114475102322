import { Col, Row, View } from "@amzn/stencil-react-components/layout";
import TranslationKeys from "src/constants/translation-keys";
import { Text } from "@amzn/stencil-react-components/text";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { Checkbox, Input } from "@amzn/stencil-react-components/form";
import ErrorMessageBanner from "src/components/preHires/ErrorMessageBanner";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    setCharLimitCheck,
    setEnteredPassword,
    setEnteredPasswordAgain,
    setPasswordMatch,
    setSpecialCharCheck
} from "src/redux/pre-hire/password-slice";
import { setPasswordApiError } from "src/redux/pre-hire/create-password-slice";

export default function PasswordInputComponent() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [passwordShown, setPasswordShown] = useState(false);
    const {
        enteredPassword,
        enteredPasswordAgain,
        charLimitCheck,
        specialCharCheck,
        passwordMatch
    } = useAppSelector(state => state.passwordInput);
    const {submitted, passwordApiError} = useAppSelector(state => state.createPasswordState);

    function showErrorIfPasswordsNotMatch() {
        // if passwords don't match when create password button clicked or reentered password input is not empty,
        // then show Error banner under "Confirm Password" input field
        if ((submitted || enteredPasswordAgain.length !== 0) && !passwordMatch) {
            return <ErrorMessageBanner errorMessage={t(TranslationKeys.ERRORS.PASSWORD.NOTMATCH)}/>;
        }
    }

    function showPasswordValidationErrors() {
        // Show Error banner under Password input field if password doesn't meet the basic requirements
        if ((submitted || enteredPassword.length !== 0) && (!charLimitCheck || !specialCharCheck)) {
            return <ErrorMessageBanner errorMessage={t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_RULE_NOT_MET)}/>;
        }
    }

    function handlePasswordInput(password: string) {
        checkCharacterLimit(password);
        checkSpecialCharExists(password);
        checkPasswordsMatch(password, enteredPasswordAgain);
        dispatch(setEnteredPassword(password));
        dispatch(setPasswordApiError(undefined));
    }

    function handlePasswordReentry(passwordAgain: string) {
        checkPasswordsMatch(enteredPassword, passwordAgain);
        dispatch(setEnteredPasswordAgain(passwordAgain));
    }

    function checkCharacterLimit(password: string) {
        const result = password?.length >= 8;
        dispatch(setCharLimitCheck(result));
    }

    // Contains special characters
    function checkSpecialCharExists(password: string) {
        const result: boolean = /[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/g.test(password);
        dispatch(setSpecialCharCheck(result));
    }

    function checkPasswordsMatch(password: string, passwordAgain: string) {
        const result = password !== "" && password === passwordAgain;
        dispatch(setPasswordMatch(result));
    }

    // Error banner if API call to create password failed e.g. Invalid Password exception
    function renderGlobalError() {
        if (passwordApiError && passwordApiError.length > 0) {
            return <ErrorMessageBanner errorMessage={passwordApiError}/>;
        }
    }

    return (
        <Col gridGap="S400">
            <View>
                <Col gridGap="S200">
                    <div id="input-id-1">
                        <Text fontSize={"T200"}>
                            {t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_PASSWORD)}
                        </Text>
                    </div>
                    <div>
                        <Input data-testid="password-entry-box" style={{width: "100%"}}
                               type={passwordShown ? "text" : "password"}
                               onBlur={(event) => {
                                   handlePasswordInput(event.target.value);
                               }}/>
                    </div>
                    { showPasswordValidationErrors() }
                    { renderGlobalError() }
                    <div id="input-id-1">
                        <Text fontSize={"T200"}>
                            {t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_CONFIRM_PASSWORD_LABEL)}
                        </Text>
                    </div>
                    <div>
                        <Input data-testid="password-re-entry-box" style={{width: "100%"}}
                               type={passwordShown ? "text" : "password"}
                               onBlur={(event) => {
                                   handlePasswordReentry(event.target.value);
                               }}/>
                    </div>
                    { showErrorIfPasswordsNotMatch() }
                </Col>
            </View>
            <Row gridGap="S200" alignItems={"center"}>
                <Checkbox data-testid="toggle-show-password"
                          name="controlled"
                          onChange={(e) => {
                              setPasswordShown(e.target.checked)
                          }}/>
                <Text fontSize={"T200"}>
                    {t(TranslationKeys.UI.PRE_HIRES.OTP_AND_PASSWORD_SHOW_PASSWORD_LABEL)}
                </Text>
            </Row>
        </Col>
    );
}
