import { Col, View } from "@amzn/stencil-react-components/layout";
import TranslationKeys from "src/constants/translation-keys";
import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck, faCircleExclamation, } from "@fortawesome/free-solid-svg-icons";
import { Text } from "@amzn/stencil-react-components/text";
import { useAppSelector } from "src/redux/hooks";

export default function PasswordRulesComponent() {
    const { t } = useTranslation();
    const {
        enteredPassword,
        charLimitCheck,
        specialCharCheck
    } = useAppSelector(state => state.passwordInput);
    const submitButtonClicked = useAppSelector(state => state.createPasswordState.submitted);

    // Show password validation result only when create password button pressed or there is entry in the password field
    function canShowPasswordValidationResult() {
        return (submitButtonClicked || enteredPassword.length !== 0);
    }

    function renderPasswordRules(rulePassed: boolean | undefined, index: number) {
        return (
            <div className="password-rules">
                <FontAwesomeIcon data-testid={`password-rule-${index}`}
                                 icon={canShowPasswordValidationResult() ? (rulePassed ? faCircleCheck : faCircleExclamation) : faCircle}
                                 color={canShowPasswordValidationResult() ? (rulePassed ? "#23756B" : "#DA3733") : "#E8EAEE"}/>
                <Text fontSize="T200"
                      color={canShowPasswordValidationResult() ? (rulePassed ? "#23756B" : "#DA3733") : "#56616C"}>
                    {t(TranslationKeys.UI.PRE_HIRES[`PASSWORD_CREATION_RULE${index}`])}
                </Text>
            </div>
        );
    }

    return (
        <Col gridGap={"S300"}>
            <View className="description">
                <Text fontSize={"T300"} fontWeight={"bold"}>
                    {t(TranslationKeys.UI.PRE_HIRES.OTP_AND_PASSWORD_REQUIREMENTS)}
                </Text>
            </View>
            <View>
                <Text fontSize={"T200"}>
                    {t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_RULE5)}
                </Text>
            </View>
            { renderPasswordRules(charLimitCheck, 1)}
            { renderPasswordRules(specialCharCheck, 2)}
        </Col>
    );
}
