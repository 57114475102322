import React from "react";
import { useTranslation } from "react-i18next";
import TranslationKeys from "src/constants/translation-keys";
import OmnitureHelper from "src/helpers/omniture-helper";

const localGlobeImage: string = require('/src/images/assets_globe.svg').default;

// TODO: Be sure to load the globe remotely on enter-password screen.
// The Help component is loaded in multiple screens but we only want this part of the CAPTCHA flow to happen in enter-password screen.
export default function Help({ globeImagePath = localGlobeImage, isErrorScreen = false }) {
    const { t } = useTranslation();

    function onFaqModalLinkClick(event: any) {
        event.preventDefault();
        OmnitureHelper.setOmniturePageTitle(' - FAQ');
    }

    return (
        <div className="help-container txt-cntr">
            <div className="globe-container" style={ isErrorScreen ?  { display: 'none' } : {}} aria-hidden="true">
                <img src={globeImagePath} alt="" onError={ (e) => { (e.target as HTMLImageElement).src = localGlobeImage }} />
            </div>
            <div className="dropdown-container" style={ isErrorScreen ?  { display: 'none' } : {}}>
                {/* TODO: Stop using JQuery and consider migrating to react-bootstrap */}
                <a href="#" className="implementation-label left-margin" data-target="#languageSelectionModal" data-toggle="modal" id="languageSelectionModalLink">
                    {t(TranslationKeys.UI.HELP.CHOOSE_LANGUAGE)}
                </a>
            </div>
            <div className="help-content-container">
                <div className={`${isErrorScreen ? 'help-content-no-border' : 'help-content'}`}>
                    <p className="grey-base zero-margin"> {t(TranslationKeys.UI.HELP.TEXT)}
                    {/* TODO: Stop using JQuery and consider migrating to react-bootstrap */}
                    <a href="#" className="zero-margin underlined" data-target="#faqModal" data-toggle="modal" id="faqModalLink" onClick={onFaqModalLinkClick}> {t(TranslationKeys.UI.HELP.VIEW_FAQ)} </a></p>
                </div>
            </div>
        </div>
    );
}
