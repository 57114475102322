import React, { useState } from 'react';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import TranslationKeys from "src/constants/translation-keys";
import { Button, ButtonVariant, ButtonSize } from '@amzn/stencil-react-components/button';
import { ToggleButton, ToggleButtonGroup } from '@amzn/stencil-react-components/toggle-button';
import { Select } from '@amzn/stencil-react-components/form';
import { Text } from '@amzn/stencil-react-components/text';
import { Col } from '@amzn/stencil-react-components/layout';
import { Modal, WithModal, ModalContent } from '@amzn/stencil-react-components/modal';
import I18nHelper from 'src/helpers/i18nHelper';
const i18nConfig = require('../../../configuration/i18nConfig');
import { getLogger } from "src/logger/logger";
import { METRIC_NAME } from "src/logger/loggerTypes";
import { useAppSelector } from "src/redux/hooks";
import CookieNames from "src/constants/cookie-names";
import { Analytics } from '@aws-amplify/analytics';
import CookieHelper from "src/helpers/cookie-helper";

export default function LanguageSelectorModal() {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const login: string = useAppSelector(state => state.welcomeInfo.login);

    const open = () => {
      setIsOpen(true);
      Analytics.record({
      name: METRIC_NAME.PREHIRE_DEFAULT_LANGUAGE,
      attributes: { username: login, defaultLanguage: i18nConfig.langArray[I18nHelper.getIndexOfSelectedLanguage()]}});
    };

    const close = () => {
      setIsOpen(false);
    };

    const renderKeys = () => {
        var langArray = i18nConfig.langArray
        return langArray.map((val?:string) => {
            return <Button>{val}</Button>
        });
    };

    function changeLanguage(value?: string) {
        i18n.changeLanguage(i18nConfig.langMap.get(value), () => {
        }).then();
    }

    const renderModal = ({ close }: { close: () => void }) => (
        <ModalContent
            titleText={t(TranslationKeys.FOOTER.LANGUAGE.CHANGE_LANGUAGE)}
            buttons={[
            <Button onClick={close} key={'button-key'} variant={ButtonVariant.Primary}>
                {t(TranslationKeys.UI.BUTTONS.CLOSE)}
            </Button>
            ]}>
            <Select
                labelId="language-selector-label"
                id="language-selector"
                options={i18nConfig.langArray}
                defaultValue={i18nConfig.langArray[I18nHelper.getIndexOfSelectedLanguage()]}
                onChange={value => {changeLanguage(value);
                CookieHelper.setCookieWithPassportDomain(CookieNames.PREFERRED_LANGUAGE,
                    i18nConfig.langMap.get(value).slice(0,2));
                CookieHelper.setCookieWithPassportDomain(CookieNames.PREFERRED_COUNTRY,
                    i18nConfig.langMap.get(value).slice(-2));
                Analytics.record({
                    name: METRIC_NAME.PREHIRE_CHANGE_LANGUAGE_MODAL_CLICK,
                    attributes: { username: login, selectedLanguage: value}
                })}}
                data-testid="languageSelection"
            />
        </ModalContent>
);

    return (
        <WithModal renderModal={renderModal} isScrollable={false}>
            {({ open }) => (
            <Button data-testid="languageButton" size={ButtonSize.Small} variant={ButtonVariant.Tertiary} aria-describedby="sroDialog" onClick={open}>
                <div className="footer-text">
                    {t(TranslationKeys.FOOTER.LANGUAGE.CHANGE_LANGUAGE)}
                </div>
            </Button>
            )}
        </WithModal>
    );
}
