import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";
import AssetsLogo from '@amzn/passport-v1ui/dist/assets_logo.svg';
import TranslationKeys from "src/constants/translation-keys";

export default function ForgotPasswordScreen() {
    const { t } = useTranslation();

    useEffect(() => {
        document.body.classList.add(
            'login-page',
            'use-style-guide'
        );
   });

    return (
        <div className='background-top'>
            <div className='centered-content-relative'>
                <div className="logo txt-cntr">
                    <img src={AssetsLogo} alt={t(TranslationKeys.IMAGES.CAPTIONS.ATOZ_LOGO)} />
                </div>
                <div className='content-box'>Forgot password placeholder screen</div>
            </div>
        </div>
    );
}