import React from 'react';
import ReactDOM from 'react-dom';
import { KatSpinner } from '@amzn/katal-react';

import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import './i18n';

// import '@amzn/katal-components/styles.css';

// In general, we would not want to make any changes specific to this package.
// We would want to edit https://code.amazon.com/packages/PassportV1UI/trees/mainline.
// The index.scss file should only be used for special cases that pertains to only this package.
// import './index.scss';
import '@amzn/passport-v1ui/dist/loader.css';

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

ReactDOM.render(
  <React.StrictMode>
    <StencilProvider>
        <AppInitWrapper>
            <React.Suspense fallback={<KatSpinner size='large' />}>
                <App />
            </React.Suspense>
        </AppInitWrapper>
    </StencilProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
