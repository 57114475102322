import { Analytics } from '@aws-amplify/analytics';
import { Auth } from '@aws-amplify/auth';
import katalTemplateString from "src/util/helpers";
import Config from "src/analytics/dev-config";

const COGNITO_IDENTITY_POOL_ID = katalTemplateString('us-west-2:f655fc9a-ad81-4cbd-9e01-743db5764628', Config.COGNITO_IDENTITY_POOL_ID_DEV);
const PINPOINT_APP_ID = katalTemplateString('0271ced19ec943ec93152319c4471948', Config.PINPOINT_APP_ID_DEV);

const authConfig = {
    Auth: {
        identityPoolId: COGNITO_IDENTITY_POOL_ID,
        region: 'us-west-2',
    }
}

const analyticsConfig = {
    AWSPinpoint: {
        appId: PINPOINT_APP_ID,
        region: 'us-west-2',
        mandatorySignIn: false,
    }
}

const pageViewConfig = {
    enable: true,
    eventName: 'pageView',
    type: 'multiPageApp',
    attributes: {
      referer: document.referrer,
    },
    provider: 'AWSPinpoint',
    getUrl: () => window.location.href.toString(),
  };

function initializeAmplify(): void {
    Auth.configure(authConfig);
    Analytics.configure(analyticsConfig);
    Analytics.autoTrack('pageView', pageViewConfig);
    Analytics.enable();
}

export default initializeAmplify;

