/**
 * This file exists _exclusively_ to provide semantically meaningful constants that can be used to access translation
 * strings. It's super easy to mis-type translation strings in the actual TSX and end up with non-functional translations.
 * Instead, by accessing with constants, we can maintain a useful heirarchical structure in the data that can be accessed
 * easily in the views and type-checked at compile time to prevent errors.
 */
type faqMapType = {
    [key: string]: string
}

type UIType = {
    [key: string]: any
}

export default class TranslationKeys {
    public static readonly ERRORS = {
        PASSWORD: {
            BLANK: 'messages_errors_blankPassword',
            NOTMATCH: 'messages_newhires_error_message_password_not_match',
            NOT_MEET_RULES: 'messages_errors_passwordReset_password_strength'
        },
        OTP: {
            INCORRECT:  'messages_newhires_error_message_incorrect_otp'
        }
    };
    public static readonly IMAGES = {
        CAPTIONS: {
            ATOZ_LOGO: 'messages_images_captions_atoz_logo'
        }
    };
    public static readonly FOOTER = {
        LANGUAGE: {
            CHANGE_LANGUAGE: 'messages_newhires_footer_changeLanguage',
            CHOOSE_LANGUAGE: 'messages_help_chooseLanguage',
            PREFERRED_LANGUAGE_HEADER: 'messages_change_language_flyout_header'
        },
        GET_HELP: 'messages_newhires_footer_getHelp'
    };
    public static readonly FAQ : faqMapType= {
        FAQ_TITLE: 'messages_faq_title',
        FAQ_LOGIN_HEADER: 'messages_faq_loginSectionHeader',
        FAQ_LOGIN_QUESTION: 'messages_faq_loginSectionQuestion',
        FAQ_LOGIN_ANSWER: 'messages_faq_loginSectionAnswer',
        FAQ_PHONE_HEADER: 'messages_faq_phoneSectionHeader',
        FAQ_PHONE_QUESTION2: 'messages_faq_phoneSectionQuestion2',
        FAQ_PHONE_ANSWER2: 'messages_faq_phoneSectionAnswer2',
        FAQ_PHONE_QUESTION3: 'messages_faq_phoneSectionQuestion3',
        FAQ_PHONE_ANSWER3: 'messages_faq_phoneSectionAnswer3',
        FAQ_OTP_HEADER: 'messages_faq_otpSectionHeader',
        FAQ_OTP_QUESTION1: 'messages_faq_otpSectionQuestion1',
        FAQ_OTP_ANSWER1_PART0:  'messages_faq_otpSectionAnswer1Part0',
        FAQ_OTP_ANSWER1_PART1: 'messages_faq_otpSectionAnswer1Part1',
        FAQ_OTP_ANSWER1_PART2: 'messages_faq_otpSectionAnswer1Part2',
        FAQ_OTP_ANSWER1_PART3: 'messages_faq_otpSectionAnswer1Part3',
        FAQ_OTP_ANSWER1_PART4: 'messages_faq_otpSectionAnswer1Part4',
        FAQ_OTP_QUESTION3: 'messages_faq_otpSectionQuestion3',
        FAQ_OTP_ANSWER3: 'messages_faq_otpSectionAnswer3',
        FAQ_OTP_QUESTION4: 'messages_faq_otpSectionQuestion4',
        FAQ_OTP_ANSWER4: 'messages_faq_otpSectionAnswer4',
        FAQ_OTP_QUESTION5: 'messages_faq_otpSectionQuestion5',
        FAQ_OTP_ANSWER5: 'messages_faq_otpSectionAnswer5',
        FAQ_PASSWORD_HEADER: 'messages_faq_passwordSectionHeader',
        FAQ_PASSWORD_QUESTION1: 'messages_faq_passwordSectionQuestion1',
        FAQ_PASSWORD_ANSWER1: 'messages_faq_passwordSectionAnswer1',
        FAQ_PASSWORD_QUESTION2: 'messages_faq_passwordSectionQuestion2',
        FAQ_PASSWORD_ANSWER2: 'messages_faq_passwordSectionAnswer2',
        FAQ_PASSWORD_QUESTION3: 'messages_faq_passwordSectionQuestion3',
        FAQ_PASSWORD_ANSWER3: 'messages_faq_passwordSectionAnswer3',
        FAQ_PASSWORD_QUESTION4:  'messages_faq_passwordSectionQuestion4',
        FAQ_PASSWORD_ANSWER4: 'messages_faq_passwordSectionAnswer4',
        FAQ_PASSWORD_QUESTION5: 'messages_faq_passwordSectionQuestion5',
        FAQ_PASSWORD_ANSWER5: 'messages_faq_passwordSectionAnswer5',
        FAQ_PASSWORD_QUESTION6: 'messages_faq_passwordSectionQuestion6',
        FAQ_PASSWORD_ANSWER6: 'messages_faq_passwordSectionAnswer6',
        FAQ_PASSWORD_QUESTION7: 'messages_faq_passwordSectionQuestion7',
        FAQ_PASSWORD_ANSWER7: 'messages_faq_passwordSectionAnswer7',
        FAQ_PASSWORD_QUESTION8: 'messages_faq_passwordSectionQuestion8',
        FAQ_PASSWORD_ANSWER8: 'messages_faq_passwordSectionAnswer8',
        FAQ_ERC_HEADER: 'messages_faq_ercSectionHeader',
        FAQ_ERC_QUESTION1: 'messages_faq_ercSectionQuestion1',
        FAQ_ERC_ANSWER1_US: 'messages_faq_ercSectionAnswer1UnitedStates',
        FAQ_ERC_ANSWER1_US_CONTACT: 'messages_faq_ercSectionAnswer1UnitedStatesContact',
        FAQ_ERC_ANSWER1_UK: 'messages_faq_ercSectionAnswer1UnitedKingdom',
        FAQ_ERC_ANSWER1_UK_CONTACT: 'messages_faq_ercSectionAnswer1UnitedKingdomContact',
        FAQ_ERC_ANSWER1_CA: 'messages_faq_ercSectionAnswer1Canada',
        FAQ_ERC_ANSWER1_CA_CONTACT: 'messages_faq_ercSectionAnswer1CanadaContact',
        FAQ_ERC_ANSWER1_COSTA_RICA: 'messages_faq_ercSectionAnswer1CostaRica',
        FAQ_ERC_ANSWER1_COSTA_RICA_CONTACT: 'messages_faq_ercSectionAnswer1CostaRicaContact',
        FAQ_ERC_ANSWER1_CZECH: 'messages_faq_ercSectionAnswer1CzechRepublic',
        FAQ_ERC_ANSWER1_CZECH_CONTACT: 'messages_faq_ercSectionAnswer1CzechRepublicContact',
        FAQ_ERC_ANSWER1_IRELAND: 'messages_faq_ercSectionAnswer1Ireland',
        FAQ_ERC_ANSWER1_IRELAND_CONTACT: 'messages_faq_ercSectionAnswer1IrelandContact',
        FAQ_ERC_ANSWER1_POLAND: 'messages_faq_ercSectionAnswer1Poland',
        FAQ_ERC_ANSWER1_POLAND_CONTACT: 'messages_faq_ercSectionAnswer1PolandContact',
        FAQ_ERC_ANSWER1_SOUTH_AFRICA: 'messages_faq_ercSectionAnswer1SouthAfrica',
        FAQ_ERC_ANSWER1_SOUTH_AFRICA_CONTACT: 'messages_faq_ercSectionAnswer1SouthAfricaContact',
        FAQ_ERC_ANSWER1_INDIA: 'messages_faq_ercSectionAnswer1India',
        FAQ_ERC_ANSWER1_INDIA_CONTACT: 'messages_faq_ercSectionAnswer1IndiaContact',
        FAQ_ERC_ANSWER1_GERMANY: 'messages_faq_ercSectionAnswer1Germany',
        FAQ_ERC_ANSWER1_GERMANY_CONTACT: 'messages_faq_ercSectionAnswer1GermanyContact',
        FAQ_ERC_ANSWER1_FRANCE: 'messages_faq_ercSectionAnswer1France',
        FAQ_ERC_ANSWER1_FRANCE_CONTACT: 'messages_faq_ercSectionAnswer1FranceContact',
        FAQ_ERC_ANSWER1_ITALY: 'messages_faq_ercSectionAnswer1Italy',
        FAQ_ERC_ANSWER1_ITALY_CONTACT: 'messages_faq_ercSectionAnswer1ItalyContact',
        FAQ_ERC_ANSWER1_SLOVAK: 'messages_faq_ercSectionAnswer1Slovak',
        FAQ_ERC_ANSWER1_SLOVAK_CONTACT: 'messages_faq_ercSectionAnswer1SlovakContact',
        FAQ_ERC_ANSWER1_SPAIN: 'messages_faq_ercSectionAnswer1Spain',
        FAQ_ERC_ANSWER1_SPAIN_CONTACT: 'messages_faq_ercSectionAnswer1SpainContact',
        FAQ_ERC_ANSWER1_AUSTRALIA: 'messages_faq_ercSectionAnswer1Australia',
        FAQ_ERC_ANSWER1_AUSTRALIA_CONTACT: 'messages_faq_ercSectionAnswer1AustraliaContact',
        FAQ_ERC_ANSWER1_BAHRAIN: 'messages_faq_ercSectionAnswer1Bahrain',
        FAQ_ERC_ANSWER1_BAHRAIN_CONTACT: 'messages_faq_ercSectionAnswer1BahrainContact',
        FAQ_ERC_ANSWER1_BELGIUM: 'messages_faq_ercSectionAnswer1Belgium',
        FAQ_ERC_ANSWER1_BELGIUM_CONTACT: 'messages_faq_ercSectionAnswer1BelgiumContact',
        FAQ_ERC_ANSWER1_CHINA: 'messages_faq_ercSectionAnswer1China',
        FAQ_ERC_ANSWER1_CHINA_CONTACT: 'messages_faq_ercSectionAnswer1ChinaContact',
        FAQ_ERC_ANSWER1_EGYPT: 'messages_faq_ercSectionAnswer1Egypt',
        FAQ_ERC_ANSWER1_EGYPT_CONTACT: 'messages_faq_ercSectionAnswer1EgyptContact',
        FAQ_ERC_ANSWER1_FINLAND: 'messages_faq_ercSectionAnswer1Finland',
        FAQ_ERC_ANSWER1_FINLAND_CONTACT: 'messages_faq_ercSectionAnswer1FinlandContact',
        FAQ_ERC_ANSWER1_JAPAN: 'messages_faq_ercSectionAnswer1Japan',
        FAQ_ERC_ANSWER1_JAPAN_CONTACT: 'messages_faq_ercSectionAnswer1JapanContact',
        FAQ_ERC_ANSWER1_MEXICO: 'messages_faq_ercSectionAnswer1Mexico',
        FAQ_ERC_ANSWER1_MEXICO_CONTACT: 'messages_faq_ercSectionAnswer1MexicoContact',
        FAQ_ERC_ANSWER1_MOROCCO: 'messages_faq_ercSectionAnswer1Morocco',
        FAQ_ERC_ANSWER1_MOROCCO_CONTACT: 'messages_faq_ercSectionAnswer1MoroccoContact',
        FAQ_ERC_ANSWER1_NETHERLANDS: 'messages_faq_ercSectionAnswer1Netherlands',
        FAQ_ERC_ANSWER1_NETHERLANDS_CONTACT: 'messages_faq_ercSectionAnswer1NetherlandsContact',
        FAQ_ERC_ANSWER1_NEW_ZEALAND: 'messages_faq_ercSectionAnswer1NewZealand',
        FAQ_ERC_ANSWER1_NEW_ZEALAND_CONTACT: 'messages_faq_ercSectionAnswer1NewZealandContact',
        FAQ_ERC_ANSWER1_PHILIPPINES: 'messages_faq_ercSectionAnswer1Philippines',
        FAQ_ERC_ANSWER1_PHILIPPINES_CONTACT: 'messages_faq_ercSectionAnswer1PhilippinesContact',
        FAQ_ERC_ANSWER1_ROMANIA: 'messages_faq_ercSectionAnswer1Romania',
        FAQ_ERC_ANSWER1_ROMANIA_CONTACT: 'messages_faq_ercSectionAnswer1RomaniaContact',
        FAQ_ERC_ANSWER1_SINGAPORE: 'messages_faq_ercSectionAnswer1Singapore',
        FAQ_ERC_ANSWER1_SINGAPORE_CONTACT: 'messages_faq_ercSectionAnswer1SingaporeContact',
        FAQ_ERC_ANSWER1_SWEDEN: 'messages_faq_ercSectionAnswer1Sweden',
        FAQ_ERC_ANSWER1_SWEDEN_CONTACT: 'messages_faq_ercSectionAnswer1SwedenContact',
        FAQ_ERC_ANSWER1_SWITZERLAND: 'messages_faq_ercSectionAnswer1Switzerland',
        FAQ_ERC_ANSWER1_SWITZERLAND_CONTACT: 'messages_faq_ercSectionAnswer1SwitzerlandContact',
        FAQ_ERC_ANSWER1_UNITED_EMIRATES: 'messages_faq_ercSectionAnswer1UnitedArabEmirates',
        FAQ_ERC_ANSWER1_UNITED_EMIRATES_CONTACT: 'messages_faq_ercSectionAnswer1UnitedArabEmiratesContact',
    }
    public static readonly UI : UIType = {
        TITLE: 'messages_hub_title',
        BUTTONS: {
            LOGIN: 'messages_passport_button_login',
            CONTINUE: 'messages_passport_button_continue',
            VERIFY: 'messages_newhires_button_verify',
            START_ATOZ: 'messages_newhires_button_startAtoZ',
            CLOSE: 'messages_passport_button_close',
            BACK: 'messages_passport_button_back',
            SEND_CODE: 'messages_passwordReset_button_sendCode',
            RESEND_CODE: 'messages_passport_button_resendCode',
            CREATE_PASSWORD: 'messages_newhires_button_createPassword',
        },
        LOGIN: {
            LABEL: 'messages_username_loginTextBoxHeading',
            PLACEHOLDER: 'messages_username_loginPlaceHolder',
            IDLE_WARNING1: 'messages_login_idleWarning1',
            IDLE_WARNING2: 'messages_login_idleWarning2',
        },
        PASSWORD: {
            TOP_HEADER: 'messages_password_topHeading',
            LABEL: 'messages_password_passwordTextBoxHeading',
            DESCRIPTION: 'messages_password_description',
            PLACEHOLDER: 'messages_password_passwordPlaceHolder',
            SHOW_PASSWORD: 'messages_password_showPassword'
        },
        PASSWORD_RESET: {
            TOP_HEADER: 'messages_passwordReset_selectEmail_titleHeader',
            LINK_TEXT: 'messages_password_resetPasswordLink',
            INSTRUCTIONS: 'messages_passwordReset_selectEmail_instructions',
        },
        PRE_HIRES: {
            WELCOME_HEADER: 'messages_newhires_welcome_topHeading',
            WELCOME_DESCRIPTION: 'messages_newhires_welcome_description',
            WELCOME_PREFERRED_LANG: 'messages_newhires_welcome_preferredLanguage',
            OTP_DESTINATION_HEADER: 'messages_newhires_otp_destination_topHeading',
            OTP_DESTINATION_DESCRIPTION: 'messages_newhires_otp_destination_description',
            OTP_DESTINATION_SMS: 'messages_newhires_otp_destination_sms',
            OTP_DESTINATION_EMAIL: 'messages_newhires_otp_destination_email',
            OTP_DESTINATION_NONE_WORK: 'messages_newhires_otp_destination_noneWork',
            OTP_INVALID_ENTRY: 'messages_errors_invalidOTPCode',
            OTP_LENGTH_CHECK_FAIL: 'messages_newhires_errors_otpError',
            OTP_RESEND_EXCEPTION: 'messages_errors_throttlingExceptionErrorDesc', // TODO: Add dedicated text
            OTP_RESEND_SUCCESS: 'messages_newhires_otpAndPassword_sendCodeSuccess',
            OTP_RESEND_TOO_MANY_ATTEMPTS: 'messages_errors_passwordReset_sendPin_attempts', // TODO: Add dedicated text
            OTP_SEND_CODE_ANOTHER_WAY: 'messages_newhires_otpAndPassword_sendAnotherWay',
            OTP_VERIFICATION_HEADER: 'messages_newhires_otp_verification_topHeading',
            OTP_VERIFICATION_DESCRIPTION1: 'messages_newhires_otp_verification_description1',
            OTP_VERIFICATION_DESCRIPTION2: 'messages_newhires_otp_verification_description2',
            OTP_UNAUTHORIZED_ERROR: 'messages_newhires_errors_otpError_unauthorized',
            OTP_GENERIC_ERROR: 'messages_newhires_errors_otpError_generic',
            PASSWORD_CREATION_HEADER: 'messages_newhires_passwordCreation_topHeading',
            PASSWORD_CREATION_DESCRIPTION: 'messages_newhires_passwordCreation_description',
            PASSWORD_CREATION_GLOBAL_ERROR1: 'messages_newhires_errors_passwordError1',
            PASSWORD_CREATION_GLOBAL_ERROR2: 'messages_newhires_errors_passwordError2',
            PASSWORD_CREATION_TEXT_BOX: 'messages_password_passwordTextBoxHeading',
            PASSWORD_CREATION_CONFIRM_PASSWORD_LABEL: 'messages_passwordReset_resetToken_confirmPasswordLabel',
            PASSWORD_CREATION_PASSWORD: 'messages_newhires_passwordCreation_password',
            PASSWORD_CREATION_CONFIRM: 'messages_newhires_passwordCreation_confirmation',
            PASSWORD_CREATION_RULE1: 'messages_newhires_passwordCreation_rule1',
            PASSWORD_CREATION_RULE2: 'messages_newhires_passwordCreation_rule2',
            PASSWORD_CREATION_RULE3: 'messages_newhires_passwordCreation_rule3',
            PASSWORD_CREATION_RULE5: 'messages_newhires_passwordCreation_rule5',
            PASSWORD_CREATION_RULE_NOT_MET: 'messages_newhires_errors_passwordRulesNotMet',
            OTP_AND_PASSWORD_HEADER: 'messages_newhires_otpAndPassword_topHeading',
            OTP_AND_PASSWORD_REQUIREMENTS: 'messages_newhires_otpAndPassword_requirements',
            OTP_AND_PASSWORD_LABEL:'messages_newhires_otpAndPassword_otpPlaceholder_label',
            OTP_AND_PASSWORD_SHOW_PASSWORD_LABEL:'messages_password_showPassword',
            DOWNLOAD_APP_HEADER: 'messages_newhires_appDownloads_topHeading',
            DOWNLOAD_APP_DESCRIPTION: 'messages_newhires_appDownloads_description',
            DOWNLOAD_APP_DESCRIPTION_MOBILE: 'messages_newhires_appDownloads_description_mobile',
            DOWNLOAD_APP_LOGIN_DETAILS_TITLE: 'messages_newhires_appDownloads_loginDetails_title',
            DOWNLOAD_APP_LOGIN_DETAILS_BODY: 'messages_newhires_appDownloads_loginDetails_body',
            DOWNLOAD_APP_LOGIN_TEXT: 'messages_username_loginTextBoxHeading',
            DOWNLOAD_APP_GUIDE: 'messages_newhires_appDownloads_download',
            GENERAL_FALLBACK_ERROR: 'messages_badge_error',
            SESSION_INVALID_ERROR: 'messages_newhires_errors_sessionInvalid',
            SESSION_EXPIRED_ERROR: 'messages_newhires_errors_sessionExpired',
            UNEXPECTED_ERROR: 'messages_errors_pinpointExceptionDesc',
            GENERIC_ERROR: 'messages_newhires_errors_genericError',
        },
        BADGE: {
            INSTRUCTION: 'messages_badge_instructions',
            FALLBACK: 'messages_badge_fallback',
            CANCEL: 'messages_badge_cancel',
        },
        HELP: {
            CHOOSE_LANGUAGE: 'messages_help_chooseLanguage',
            TEXT: 'messages_help_text',
            VIEW_FAQ: 'messages_help_viewFAQ',
        }
    };
}
