import QueryString from 'query-string';
import { setLogin } from 'src/redux/credential-slice';
import Attributes from "src/constants/attributes";
import { Dispatch, AnyAction } from '@reduxjs/toolkit';

interface Location {
    search: string;
}

interface Dispatcher {
    dispatch: Dispatch<AnyAction>;
}

export default class StateInitializationHelper {
    public static initializeState(location: Location, store: Dispatcher) {
        // This function needs to take all the query params and map them in to
        // their respective state. Please do NOT add any parameters this way that
        // it could be harmful or insecure if the user were able to tamper with them.
        // Those should be retrieved via an API request to Passport (where they can
        // be retrieved from the session or cookies) instead.
        const query = QueryString.parse(location.search);

        // Parse out the employee's username.
        if (query[Attributes.LOGIN]) {
            let login = query[Attributes.LOGIN]
            if (Array.isArray(login)) {
                login = login.filter(l => l)[0] || '';
            }
            // We don't want to allow null as a possible state, so initialize it with empty string if it's falsey
            login = login || '';
            store.dispatch(setLogin(login));
        }
    }
}