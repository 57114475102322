import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AssetsLogo from '@amzn/passport-v1ui/dist/assets_logo_blue.svg';
import TranslationKeys from "src/constants/translation-keys";
import 'src/styles/prehires.scss'
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Col, View } from '@amzn/stencil-react-components/layout';
import { Select } from '@amzn/stencil-react-components/form';
import i18n from 'i18next';
import I18nHelper from 'src/helpers/i18nHelper';
import ReactRoutes from 'src/constants/react-routes';
import { useNavigate } from 'react-router';
const i18nConfig = require('../../../configuration/i18nConfig');
import { getLogger } from "src/logger/logger";
import { METRIC_NAME } from "src/logger/loggerTypes";
import { COMPONENT_EVENT } from "src/logger/loggerConstants";
import CookieNames from "src/constants/cookie-names";
import CookieHelper from "src/helpers/cookie-helper";

interface WelcomeContentViewProps {
    login: string,
}

function changeLanguage(value?: string) {
        i18n.changeLanguage(i18nConfig.langMap.get(value), () => {
        }).then();
    }

function WelcomeContentView(props: WelcomeContentViewProps) {
    const { t } = useTranslation();
    const { login } = props;
    const navigate = useNavigate();
    const logger = getLogger();

    function handleContinueButtonClick() {
        navigate(ReactRoutes.PREHIRES_OTP_DESTINATION);
    }

    return (
            <View className="prehires-hero-content">
                <Col gridGap="S300">
                    <View className="logo">
                        <img src={AssetsLogo}/>
                    </View>
                    <View className="header">
                        <div data-testid="welcomeHeader"> {t(TranslationKeys.UI.PRE_HIRES.WELCOME_HEADER)} </div>
                        <div className="header-primary">{login}</div>
                    </View>
                    <View className="description">
                        <div data-testid="welcomeBody"> {t(TranslationKeys.UI.PRE_HIRES.WELCOME_DESCRIPTION)} </div>
                    </View>
                    <View className="label-text">
                        <div data-testid="langLabel" id="language-selector">
                            {t(TranslationKeys.UI.PRE_HIRES.WELCOME_PREFERRED_LANG)}
                        </div>
                        <Select
                            labelId="language-selector-label"
                            id="language-selector"
                            options={i18nConfig.langArray}
                            defaultValue={i18nConfig.langArray[I18nHelper.getIndexOfSelectedLanguage()]}
                            onChange={value => {changeLanguage(value);
                            CookieHelper.setCookieWithPassportDomain(CookieNames.PREFERRED_LANGUAGE, i18nConfig.langMap.get(value).slice(0,2));
                            CookieHelper.setCookieWithPassportDomain(CookieNames.PREFERRED_COUNTRY, i18nConfig.langMap.get(value).slice(-2))}}
                            />
                    </View>
                    <View className="label-text" alignSelf='center' width="100%">
                        <Button data-testid="continueButton" onClick={()=>
                            {logger.info(`${COMPONENT_EVENT.PREHIRE_WELCOME_PAGE.CONTINUE_BUTTON}`);
                            handleContinueButtonClick();
                            }} variant={ButtonVariant.Primary} style={{width : '100%'}}>
                            {t(TranslationKeys.UI.BUTTONS.CONTINUE)}
                        </Button>
                    </View>
                </Col>
            </View>
    );
}

export default WelcomeContentView;
