import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CreatePasswordState {
    submitted: boolean,
    resetPinApiError?: string, // any error regarding reset token from VirtuaCop when creating password e.g. wrong pin
    passwordApiError?: string // any error regarding password from VirtuaCop e.g. invalid pass
}

export const initialState: CreatePasswordState = {
    submitted: false,
}

const createPasswordSlice = createSlice({
    name: 'createPasswordState',
    initialState,
    reducers : {
        setSubmitted: (state: CreatePasswordState, action: PayloadAction<boolean>) => {
            state.submitted = action.payload;
        },
        setResetPinApiError: (state: CreatePasswordState, action: PayloadAction<string | undefined>) => {
            state.resetPinApiError = action.payload;
        },
        setPasswordApiError: (state: CreatePasswordState, action: PayloadAction<string | undefined>) => {
            state.passwordApiError = action.payload;
        },
        resetCreatePasswordStates: () => initialState
    }
});

export const { setSubmitted, setResetPinApiError, setPasswordApiError, resetCreatePasswordStates } = createPasswordSlice.actions;

export default createPasswordSlice.reducer;
