export default class ReactRoutes {
    public static readonly ROOT = '/';
    public static readonly ENTER_CREDENTIALS = ReactRoutes.ROOT;
    public static readonly RESET_PASSWORD = '/reset-password';
    public static readonly SELECT_OTP_DESTINATION = '/select-otp-destination';
    public static readonly ENTER_PASSWORD_RESET_PIN = '/enter-password-reset-pin';
    public static readonly PREHIRES_WELCOME = '/prehires-welcome';
    public static readonly PREHIRES_OTP_DESTINATION = '/prehires-otp-destination';
    public static readonly PREHIRES_DOWNLOAD_APP = '/prehires-download-app';
    public static readonly PREHIRES_TOKEN_AND_PASSWORD = '/prehires-enter-token-and-password';
    public static readonly PREHIRES_ENTER_OTP = '/prehires-enter-otp';
    public static readonly PREHIRES_CREATE_PASSWORD = '/prehires-create-password';
}