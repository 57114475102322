import React, { useMemo } from 'react';
import Attributes from "src/constants/attributes";
import '../.././styles/prehires.scss'

export type OtpInputProps = {
    value: string;
    valueLength: number;
    onChange: (value: string) => void;
}

export default function OtpInput({ value, valueLength, onChange }: OtpInputProps) {

    const valueItems = useMemo(() => {
        const valueArray = value.split('');
        const items: Array<string> = [];

        for (let i = 0; i < valueLength; i++) {
        const char = valueArray[i];

        if (Attributes.RESET_TOKEN_REG_EXP.test(char)) {
            items.push(char);
        } else {
            items.push('');
            }
        }
        return items;
    }, [value, valueLength]);

    const focusToNextInput = (target: HTMLElement) => {
        const nextElementSibling =
        target.nextElementSibling as HTMLInputElement | null;

        if (nextElementSibling) {
            nextElementSibling.focus();
        }
    };

    const focusToPrevInput = (target: HTMLElement) => {
        const previousElementSibling =
        target.previousElementSibling as HTMLInputElement | null;

        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };

    const inputOnChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        idx: number
    ) => {
        const target = e.target;
        let targetValue = target.value.trim();
        const isTargetValueDigit = Attributes.RESET_TOKEN_REG_EXP.test(targetValue);

        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }

        targetValue = isTargetValueDigit ? targetValue : ' ';

        const targetValueLength = targetValue.length;

        if (targetValueLength === 1) {
            const newValue =
            value.substring(0, idx) + targetValue + value.substring(idx + 1);
            onChange(newValue);
            if (!isTargetValueDigit) {
                return;
            }
            focusToNextInput(target);
        } else if (targetValueLength === valueLength) {
            onChange(targetValue);
            target.blur();
        }
    };

    const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        const target = e.target as HTMLInputElement;
        const targetValue = target.value;

        if (key === 'ArrowRight' || key === 'ArrowDown') {
            e.preventDefault();
            return focusToNextInput(target);
        }

        if (key === 'ArrowLeft' || key === 'ArrowUp') {
            e.preventDefault();
            return focusToPrevInput(target);
        }

        target.setSelectionRange(0, targetValue.length);

        if (e.key !== 'Backspace' || target.value !== '') {
            return;
        }
        focusToPrevInput(target);
    };

    const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const { target } = e;

        target.setSelectionRange(0, target.value.length);
    };

    return (
        <div className="otp-group">
        {valueItems.map((digit, index) => (
            <input
                key={index}
                type="text"
                inputMode="text"
                autoComplete="one-time-code"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                maxLength={valueLength}
                className="otp-input"
                value={digit}
                onChange={(e) => inputOnChange(e, index)}
                onKeyDown={inputOnKeyDown}
                onFocus={inputOnFocus}
            />
        ))}
        </div>
    )
}