import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TranslationKeys from "src/constants/translation-keys";
import { Checkbox, Input, InputWrapper, LabelPosition } from '@amzn/stencil-react-components/form';
import { Col } from '@amzn/stencil-react-components/layout';


export default function ShowPassword() {
    const { t } = useTranslation();

    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
       setPasswordShown(!passwordShown);
    };

    const [isChecked, setIsChecked] = useState(false);

    return (
       <Col gridGap="S300">
           <Col gridGap="S200">
               <div className="label-text"> {t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_TEXT_BOX)} </div>
               <Input type={passwordShown ? "text" : "password"} />
           </Col>
           <Col gridGap="S200">
               <div className="label-text"> {t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_CONFIRM_PASSWORD_LABEL)} </div>
               <Input type={passwordShown ? "text" : "password"} />
           </Col>
           <InputWrapper
               id="checkbox-controlled"
               labelText={t(TranslationKeys.UI.PRE_HIRES.OTP_AND_PASSWORD_SHOW_PASSWORD_LABEL)}
               labelPosition={LabelPosition.Trailing}>
               {inputProps => (
                 <Checkbox
                   name="controlled"
                   onChange={togglePassword}
                 />
               )}
           </InputWrapper>
       </Col>
    );
}