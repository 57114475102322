import omniture from 'src/assets/js/omniture.js';
import KioskHelper from './kiosk-helper';

export default class OmnitureHelper {

    public static setOmniturePageTitle(title: string) {
        if (!KioskHelper.isKiosk()){ //skip omniture for kiosk
            try {
                if (typeof (omniture.pageName) == "undefined") {
                    omniture.pageName = title;
                } else if (omniture.pageName.indexOf(title) == -1) {
                    omniture.pageName = omniture.pageName + title;
                }

                omniture.t();
            } catch (e) {
                console.error("Omniture page title failed: ", e);
                this.logOmnitureFailure(title);
            }
        }
    }

    // Uses the tl() method so as not to increment page views.
    // https://tiny.amazon.com/h6ytz4j6/expeadobdocsanalimplvarsfunc
    public static trackOmnitureLinkClick(linkName: string) {
        if (!KioskHelper.isKiosk()){ //skip omniture for kiosk
            try {
                if (linkName) {
                    omniture.tl(true, "o", linkName);
                }
            } catch (e) {
                console.error("Omniture link click failed: ", e);
                this.logOmnitureFailure(linkName);
            }
        }
    }

    // TODO: Migrate this method when the corresponding screen is migrated
    // function trackRememberThisDevice(omniture) {
    //     if ($('#trustedDevice').prop('checked')) {
    //         trackOmnitureLinkClick(omniture, "Remember This Device");
    //     }
    // }

    public static logOmnitureFailure(titleOrLinkName: string) {
        const headers: HeadersInit = {
            'omniture-failure': titleOrLinkName,
        }
        const options : RequestInit = {
            headers: headers,
            method: 'GET'
        };
        fetch('/exception', options)
            .then()
            .catch(() => console.warn("omniture metric capture failure"));
    }
}
