import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AssetsLogo from '@amzn/passport-v1ui/dist/assets_logo_blue.svg';
import TranslationKeys from "src/constants/translation-keys";
import '../.././styles/prehires.scss'
import OtpInput from './OtpInput';
import ErrorMessageBanner from './ErrorMessageBanner';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Col, Row, View } from '@amzn/stencil-react-components/layout';
import { Text, Label} from '@amzn/stencil-react-components/text';
import { Link, LinkIconPosition } from '@amzn/stencil-react-components/link';
import { IconRefresh } from '@amzn/stencil-react-components/icons';

export default function EnterOtpView() {
    const { t } = useTranslation();

    const numOfOtpDigit = 8;

    const [otp, setOtp] = useState('');
    const onChange = (value: string) => setOtp(value);

//     TODO: delete this hardcode after we can pass user's real otp destination and resetToken from Passport
    const emailPlaceholder = "***xyz@gmail.com";
    const resetTokenPlaceholder = "Pyj8yMEj";

    const errorMessage: string = t(TranslationKeys.ERRORS.OTP.INCORRECT);
    const [errorBannerIsShown, setErrorBannerIsShown] = useState(false);

    return (
        <View className="prehires-hero-content" >
            <Col gridGap="S300">
                <View className="logo">
                    <img src={AssetsLogo}/>
                </View>
                <View data-testid="enterOtpHeader" className="header">
                    <div> {t(TranslationKeys.UI.PRE_HIRES.OTP_VERIFICATION_HEADER)} </div>
                </View>
                <View data-testid="enterOtpBody" className="label-text">
                    <div> {t(TranslationKeys.UI.PRE_HIRES.OTP_VERIFICATION_DESCRIPTION1, {0:emailPlaceholder})} </div>
                </View>
                <Link target="_blank"
                      icon={<IconRefresh aria-hidden={true} />}
                      iconPosition={LinkIconPosition.Leading}>
                    {t(TranslationKeys.UI.BUTTONS.RESEND_CODE)}
                </Link>
                <OtpInput value={otp} valueLength={numOfOtpDigit} onChange={onChange}/>
                 {errorBannerIsShown && <ErrorMessageBanner errorMessage={errorMessage} />}
                <Row gridGap="S500">
                    <Button data-testid="backButton" style={{width : '50%'}}>
                        {t(TranslationKeys.UI.BUTTONS.BACK)}
                    </Button>
                    <Button data-testid="verifyButton" variant={ButtonVariant.Primary} style={{width : '50%'}} onClick={()=>  {(otp === resetTokenPlaceholder) ?
                        alert("ResetToken is valid!") : setErrorBannerIsShown(true)} }>
                        {t(TranslationKeys.UI.BUTTONS.VERIFY)}
                    </Button>
                </Row>
            </Col>
        </View>
    );
}
