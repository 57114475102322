import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TranslationKeys from "src/constants/translation-keys";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import AssetsLogo from '@amzn/passport-v1ui/dist/assets_logo_blue.svg';
import ShowPassword from './ShowPassword';
import '../.././styles/prehires.scss'
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Col, View } from '@amzn/stencil-react-components/layout';

function CreatePasswordContentView() {
    const { t } = useTranslation();

    //TODO: delete all the hardcode after we can get real data from Passport
    const userLogin = 'lorye';

    return (
        <View className="prehires-hero-content">
            <Col gridGap="S300" >
                <View className="logo">
                    <img src={AssetsLogo}/>
                </View>
                <View className="header">
                    <div data-testid="passwordCreationHeader"> {t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_HEADER)} </div>
                </View>
                <Col gridGap="S200">
                    <div className="description" data-testid="passwordCreationDescription">{t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_DESCRIPTION)}</div>
                    <div> <strong> {userLogin} </strong> </div>
                    <View>
                        <hr />
                    </View>
                </Col>
                <ul className="password-rules-text">
                        <li><FontAwesomeIcon icon={faCircle} className="fa-icon"/> {t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_RULE1)}</li>
                        <li><FontAwesomeIcon icon={faCircle} className="fa-icon"/> {t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_RULE2)}</li>
                        <li><FontAwesomeIcon icon={faCircle} className="fa-icon"/> {t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_RULE3)}</li>
                </ul>
                <ShowPassword/>
                <View alignSelf='center' width="100%">
                    <Button data-testid="createPasswordButton" variant={ButtonVariant.Primary} style={{width : '100%'}} >
                        {t(TranslationKeys.UI.BUTTONS.CREATE_PASSWORD)}
                    </Button>
                </View>
            </Col>
        </View>
    );
}

export default CreatePasswordContentView;