import store from 'src/redux/store';
import katalTemplateString from "src/util/helpers";
import Attributes from "src/constants/attributes";
import IdPRoutes from 'src/constants/idp-routes';
import QueryParams from 'src/constants/query-params';
import IdPError from 'src/errors/idp-error';

// Set up the URL that we'll be using everywhere else
const IDP_URL = katalTemplateString('https://idp.amazon.work', Attributes.IDP_URL_DEV);

export default class IdPHelper {
    private static async request(path: string, method: string, body: any) {
        // For now, assume that the csrfToken must exists during the frontend swap.
        // On initial screens (e.g. EnterPassword), we must create the csrfToken there instead.
        // We cannot generate new csrfTokens on non-initial screens when the tokens are missing.
        // This is because csrfTokens are tied to the session id and transaction id.
        let csrfToken = store.getState().apiContext.csrfToken;

        // TODO: Check if csrfToken exists, otherwise error out and display state expired error.
        // Do we want to redirect to an initial login page (e.g. EnterPassword) as well?

        // TODO: Verify if the below statement is correct and change the content if needed.
        // Use form instead of json since servlets have native support for form parsing.
        // Do note that post data can only be read once and only once natively
        // regardless of content-type.
        const headers: HeadersInit = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/x-www-form-urlencoded',
            // There are CSRF guards in place to dissuade attackers from guessing sessions.
            'should-takeover-session': 'true',
            'anti-csrftoken-a2z': csrfToken,
        }

        const options : RequestInit = {
            headers: headers,
            mode: 'cors',
            credentials: 'include',
            method: method
        };

        if (body) {
            // This is an easy way to create form body so that PassportV1 can
            // natively consume and read the payload.
            options.body = new URLSearchParams(body);
        }

        const url = `${IDP_URL}${path}`;

        let response, result;
        try {
            response = await fetch(url, options);
            let responseBody = await response.text();
            if (responseBody.length > 0) {
                result = JSON.parse(responseBody);
            }
            if (response.ok) {
                return result;
            } else {
                // TODO: Modify error behavior if needed
                throw new IdPError(Object.assign({
                    statusCode: response.status
                }, result), path);
            }
        } catch (error) {
            if (error instanceof IdPError) {
                throw error;
            }
            throw new IdPError({statusCode: 408, error: 'IdP request failed to complete'}, path);
        }
    }

    private static async get(path: string) {
        return IdPHelper.request(path, 'GET', null);
    }

    private static async post(path: string, body: any) {
        return IdPHelper.request(path, 'POST', body);
    }

    static async initIdPContext(opts: {
        transactionId: string | null,
        sessionId: string | null,
    // TODO: Add return type
    }): Promise<any> {
        if (!opts.transactionId || !opts.sessionId) {
            // TODO: Create custom errors if needed
            throw new Error('Missing transactionId or sessionId');
        }

        const path: string = `${IdPRoutes.IDP_INITIALIZE}?` + new URLSearchParams({
            [QueryParams.TRANSACTION_ID]: opts.transactionId,
            [QueryParams.SESSION_ID]: opts.sessionId,
        });
        return IdPHelper.get(path);
    }

    static async submitBadgeRFID(opts: { badgeRFID: string }) {
        return IdPHelper.post(IdPRoutes.IDP_ENTER, opts);
    }
}
