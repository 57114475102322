import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SessionStorageKeys from "src/constants/session-storage";
import TranslationKeys from "src/constants/translation-keys";
import OmnitureHelper from "src/helpers/omniture-helper";
import { redirectToAtoZWebProd } from "src/util/helpers";

export default function LoginIdleTimeout() {
    const initialCounterValue: number = 60;
    const [counter, setCounter] = useState(initialCounterValue);
    const { t } = useTranslation();

    useEffect(() => {
        let interval: ReturnType<typeof setTimeout>;
        if (counter > 0) {
            interval = setTimeout(() => setCounter(counter - 1), 1000);
        }
        return () => clearInterval(interval);
    });

    useEffect(() => {
        document.addEventListener('pointerdown', resetCounter);
        return () => document.removeEventListener('pointerdown', resetCounter);
    }, []);

    useEffect(() => {
        if (counter < 1) {
            cancelIdleLogin();
        }
    }, [counter]);

    // TODO: This should be set in the enter-password screen.
    // Since we won't have a referrer attached to this flow yet, we will redirect the user back to AtoZ.
    // Then AtoZ would redirect the user back to the enter-password page with badge cookie set to restart the flow.
    // useEffect(() => {
    //    // save the first referrer in session storage to use for idle timeout login cancellation
    //    // invalid badge entries replace the referrer, so only the first referrer should be stored
    //    if (sessionStorage.getItem(redirectTargetKey) == null) {
    //       sessionStorage.setItem(redirectTargetKey, document.referrer);
    //    }
    // }, []);

    function resetCounter() {
        setCounter(initialCounterValue);
    }

    // TODO: Might need to make this a hook (or a helper method) for idle vs active cancel login.
    // Do this once we migrate the enter-password page.
    function cancelIdleLogin() {
        OmnitureHelper.trackOmnitureLinkClick('Cancel idle login');
        // TODO: The `target` will always be null until we set this in the enter-password screen
        // Until that time, we will not crash the window (as we are doing in the non-react version) but
        // we will redirect to AtoZ, which will redirect the user back here.
        const target: string | null = sessionStorage.getItem(SessionStorageKeys.TIMEOUT_REDIRECT_TARGET);
        sessionStorage.removeItem(SessionStorageKeys.TIMEOUT_REDIRECT_TARGET);
        if (target == null) {
            // TODO: Redirect to AtoZ until we migrate the enter-password page and set the key there.
            // Crash the app if the key not found here.
            // The code is intentionally (though unnecessary) being duplicated here.
            redirectToAtoZWebProd();
        }
        redirectToAtoZWebProd();
    }

    return (
        <div className="centered-content login-idle-timeout" id="login-idle-timeout">
            <p className="login-idle-timeout-text">
                {t(TranslationKeys.UI.LOGIN.IDLE_WARNING1)}
                <span id="login-idle-timeout-time-remaining">
                    {` ${counter} `}
                </span>
                {t(TranslationKeys.UI.LOGIN.IDLE_WARNING2)}
            </p>
        </div>
    );
}
