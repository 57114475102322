import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import IdpUserContext from "src/@types/api/IdpUserContext";

export interface ContextState {
    context?: IdpUserContext
}

export const initialState: ContextState = {};

const contextSlice = createSlice({
    name: 'contexts',
    initialState,
    reducers: {
        setContext: (state: ContextState, action: PayloadAction<IdpUserContext>) => {
            state.context = action.payload;
        }
    }
});

export const { setContext } = contextSlice.actions;

export default contextSlice.reducer;
