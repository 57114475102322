import KatalLogger, { LoggerConfig } from "@amzn/katal-logger";
import React, { Component } from 'react';

export class PassportV1FrontendWebsiteLogger extends KatalLogger {
  constructor(config: LoggerConfig) {
    super(config);
  };
};

let logger: PassportV1FrontendWebsiteLogger;

export const initializeLogger = (context?: {}) => {
  logger = new PassportV1FrontendWebsiteLogger({
    url: "https://dhbjlm5ltd.execute-api.us-west-2.amazonaws.com/prod/v1/log",
    logToConsole: false,
    decodeStackTrace: false,
    context,
  });
  logger.addErrorListener();
  return logger;
};

export const LoggerContext = React.createContext({ logger: initializeLogger() });

export const getLogger = () => {
  if (logger) {
    return logger;
  }
  return initializeLogger();
};