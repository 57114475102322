import React, { useEffect } from 'react';
import Footer from './Footer';
import DownloadAppContentView from './DownloadAppContentView';
import { getLogger } from "src/logger/logger";
import { METRIC_NAME } from "src/logger/loggerTypes";
import { COMPONENT_EVENT } from "src/logger/loggerConstants";
import { useAppSelector } from "src/redux/hooks";

function DownloadAppScreen() {
    const login: string = useAppSelector(state => state.welcomeInfo.login);
    const logger = getLogger();
    useEffect(() => {
    logger.info(`${COMPONENT_EVENT.PREHIRE_DOWNLOAD_APP_PAGE.SCREEN_LOADING}`, { username: login })
    }, []);
    return (
        <div className="fullScreen">
            <div className="row-flex-container">
                <div data-testid="downloadAppImageBackground" className="flex-box-left-confirmation-background">
                </div>
                <div className="flex-box-right">
                    <div data-testid="downloadAppContent" className="content-wrap">
                        <DownloadAppContentView />
                    </div>
                    <div data-testid="footer" className="footer-container">
                        <Footer/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DownloadAppScreen;