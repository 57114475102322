import IdpUserContext, { FeatureGateStatus } from 'src/@types/api/IdpUserContext';
import { setCsrfToken } from "src/redux/api-context-slice";
import ApiError from "src/errors/api-error";
import APIRoutes from "src/constants/api-routes";
import PreHireWelcomeRequest from "src/@types/api/PreHireWelcomeRequest";
import PreHireWelcomeResponse from "src/@types/api/PreHireWelcomeResponse";
import GetContactInfoInput from 'src/@types/api/GetContactInfoInput';
import GetContactInfoOutput from 'src/@types/api/GetContactInfoOutput';
import SelectedOTPDestination from 'src/@types/api/SelectedOTPDestination';
import SetPreHirePasswordRequest from "src/@types/api/SetPreHirePasswordRequest";
import GetAPIContextResponse from "src/@types/api/GetAPIContextResponse";
import store from 'src/redux/store';
import katalTemplateString from "src/util/helpers";
import Attributes from "src/constants/attributes";
// We are using "js-cookie" here since jest cannot handle the "typescript-cookie"
// https://stackoverflow.com/questions/42260218/jest-setup-syntaxerror-unexpected-token-export
// https://github.com/carhartl/typescript-cookie/issues/110
// import CookieHelper from "./cookie-helper";
import Cookies from "js-cookie";
import CookieNames from '../constants/cookie-names';
import FeatureGateStatusHelper from 'src/helpers/feature-gate-status-helper';
import FeatureGates from 'src/constants/feature-gates';

// Set up the URL that we'll be using everywhere else
const API_URL = katalTemplateString('https://idp.amazon.work/idp/api', Attributes.API_URL_DEV);

export default class APIHelper {
    private static async request(path: string, method: string, body: any) {
      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }

      if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
          let csrfToken = store.getState().apiContext.csrfToken;

          // Refresh API context
          if (!csrfToken || !Cookies.get(CookieNames.API_CONTEXT)) {
              let response = await APIHelper.setAPIContext();
              csrfToken = response.csrfToken;
          }

          headers['anti-csrftoken-a2z'] = csrfToken;
      }

      const options : RequestInit = {
        headers: headers,
        mode: 'cors',
        credentials: 'include',
        method: method
      };
      if (body) {
        options.body = JSON.stringify(body);
      }
      const url = `${API_URL}${path}`;
      let response, result;
      try {
          response = await fetch(url, options);
          let responseBody = await response.text();
          if (responseBody.length > 0) {
              result = JSON.parse(responseBody);
          }
          if (response.ok) {
              return result;
          } else {
              throw new ApiError(Object.assign({
                  statusCode: response.status
              }, result), path);
          }
      } catch (error) {
          if (error instanceof ApiError) {
              throw error;
          }
          throw new ApiError({statusCode: 408, error: 'API request failed to complete'}, path);
      }
    }

    private static async get(path: string) {
      return APIHelper.request(path, 'GET', null);
    }

    private static async post(path: string, body: any) {
      return APIHelper.request(path, 'POST', body);
    }

    static async getIdPUserContext() : Promise<IdpUserContext> {
        return APIHelper.get(APIRoutes.IDP_USER_CONTEXT);
    }

    static async getAPIContext() : Promise<GetAPIContextResponse> {
        return APIHelper.get(APIRoutes.GET_API_CONTEXT);
    }

    static async setAPIContext() {
        const response = await APIHelper.getAPIContext();
        store.dispatch(setCsrfToken(response.csrfToken));
        return response;
    }

    static async getPasswordGainEvent(preHireWelcomeRequest: PreHireWelcomeRequest) : Promise<PreHireWelcomeResponse> {
        return APIHelper.post(APIRoutes.WELCOME_DATA, preHireWelcomeRequest);
    }

    static async getOtpDestinations(login: GetContactInfoInput) : Promise<GetContactInfoOutput> {
        return APIHelper.post(APIRoutes.GET_OTP_DESTINATIONS, login);
    }

    static async sendOTPToSelectedDestination(selectedDestination: SelectedOTPDestination) : Promise<void> {
        return APIHelper.post(APIRoutes.SEND_RESET_PIN, selectedDestination);
    }

    static async setPassword(setPreHirePasswordRequest: SetPreHirePasswordRequest) : Promise<void> {
        return APIHelper.post(APIRoutes.SET_PASSWORD, setPreHirePasswordRequest);
    }

    static async setPasswordWithSIF(setPreHirePasswordRequest: SetPreHirePasswordRequest,
                             featureGateStatus: FeatureGateStatus[] | undefined) : Promise<void> {
        const SIF_PROFILE_NAME = 'amazon-passport-v1-password-create';
        let URL: string= APIRoutes.SET_PASSWORD;
        const featureGateEnabled = featureGateStatus && new FeatureGateStatusHelper(featureGateStatus)
            .isFeatureGateEnabled(FeatureGates.PASSWORD_CREATE_SIF_FG);
        // If SIF profile name initialized from CDK and feature gate enabled
        if (!SIF_PROFILE_NAME.includes('{{') && featureGateEnabled) {
            URL = `${URL}?sif_profile=${SIF_PROFILE_NAME}`;
        }
        return APIHelper.post(URL, setPreHirePasswordRequest);
    }
}
