import { FeatureGateStatus } from 'src/@types/api/IdpUserContext';

export default class FeatureGateStatusHelper {

    featureGateStatus: FeatureGateStatus[];

    constructor(featureGateStatues: FeatureGateStatus[]) {
        this.featureGateStatus = featureGateStatues;
    }

    public isFeatureGateEnabled(featureGateName: string | undefined) : boolean {
        if (!featureGateName) return false;
        const featureGate: FeatureGateStatus | undefined= this.featureGateStatus
            .find(value => value.featureGate === featureGateName);
        return featureGate ? featureGate.active : false;
    }
}
