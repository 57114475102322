import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import badgeScanAnimation from 'src/assets/json/badge_scan_animation.json';
import AssetsLogo from '@amzn/passport-v1ui/dist/assets_logo.svg';
import TranslationKeys from "src/constants/translation-keys";
import KioskHelper from 'src/helpers/kiosk-helper';
import KioskCancelLogin from 'src/components/commons/KioskCancelLogin';
import { redirectToAtoZWebProd } from 'src/util/helpers';
import Help from 'src/components/commons/Help';
import LoginIdleTimeout from 'src/components/commons/LoginIdleTimeout';
import OmnitureHelper from 'src/helpers/omniture-helper';
import FaqModal from 'src/components/commons/FaqModal';
import LanguageModal from 'src/components/commons/LanguageModal';
import IdPHelper from 'src/helpers/idp-helper';

export default function EnterBadgeScreen() {
    const { t } = useTranslation();
    const [badgeRFId, setBadgeRFID] = useState<string>('');

    useEffect(() => {
        document.body.classList.add(
            'login-page',
            'use-style-guide'
        );
    });

    useEffect(() => {
        // TODO: Redirect the user back to the originating address if possible.
        // Otherwise redirect them back to AtoZ.
        // See original code for more info.
        if (!KioskHelper.isKiosk()) {
            redirectToAtoZWebProd();
        }
    });

    useEffect(() => {
        OmnitureHelper.setOmniturePageTitle('External Badge Scan');
    }, []);

    function updateBadgeInput(event: KeyboardEvent) {
        let key = event.key;
        if (/\d/.test(key)) {
            event.preventDefault();
            setBadgeRFID(badgeRFId + key);
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', updateBadgeInput);
        return () => {
            window.removeEventListener('keydown', updateBadgeInput);
        };
    // This is required as we are updating state outside of React's ecosystem.
    }, [badgeRFId]);

    function handleSubmit() {
        IdPHelper.submitBadgeRFID({ badgeRFID: badgeRFId })
            .then(response => {
                // TODO: Authenticate user
                // The easiest way I can think of is to:
                // 1. Update the Session with some sort of "success" state if needed
                //   - Note that cookies will by set by the server during this call
                // 2. Redirect them to the SamlSSOServlet
                // 3. Modify SamlSSOServlet to check the "success" state from the Session
                // 4. Proceed with the normal flow
                console.log(response);
            })
            .catch(error => {
                // TODO: Handle incorrect badge submission
                // Do note that we want to keep error messages DRY. User error messages
                // should be passed, handled, and processed from PassportV1 to React
                // in similar fashions.
                console.log(error);
            });
        setBadgeRFID('');
    }

    function onEnterKey(event: KeyboardEvent) {
        // If the user presses the 'Enter' key
        if (event.key === 'Enter') {
            // Prevent the 'Enter' key from causing any other side effects,
            // such as reopening the FAQ Modal
            event.preventDefault();
            handleSubmit();
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onEnterKey);
        return () => {
            window.removeEventListener('keydown', onEnterKey);
        };
    }, [badgeRFId]);

    return (
        <>
            {/* TODO: Add Banner */}
            {/* <Banner /> */}
            <div className='background-top'>
                {/* TODO: Remember to add the cancel login button across each new screen when needed */}
                <KioskCancelLogin />

                <div className='centered-content-relative'>
                    <div className="logo txt-cntr">
                        <img src={AssetsLogo} alt={t(TranslationKeys.IMAGES.CAPTIONS.ATOZ_LOGO)} />
                    </div>

                    {/* start content-box */}
                    <div className='content-box'>

                      {/* TODO: Display errors */}
                      {/*<c:if test="${ not empty errors[badge] }">
                        <div className="badge-error">
                          <div className="input-alert badge-error-alert" aria-hidden="true"> ! </div>
                          <div className="badge-error-text">${errors[badge]}</div>
                        </div>
                      </c:if>*/}

                        <div id="badge-lottie">
                            <Lottie
                                animationData={badgeScanAnimation}
                                loop={true}
                                autoplay={true}
                            />
                        </div>
                        <h1 className="top-h1 badge-header"> {t(TranslationKeys.UI.BADGE.INSTRUCTION)} </h1>

                        {/* TODO: Add fallback to use the current PassportV1's badge fallback flow
                        <%-- This is non-JS workaround to simulate an anchor text performing a POST action --%>
                        <form className="badge-fallback-form" method="POST" autoComplete="off">
                          <input type="hidden" name="${login}" value="${ sanitizedLogin }" />
                          <input type="hidden" name="${contextKey}" value="${ requestScope[contextKey] }" />
                          <input type="hidden" name="${authStep}" value="${ requestScope[authStep] }" />
                          <input type="hidden" name="${csrfName}" value="${csrfValue}" />
                          <input type="hidden" name="${badgeFallback}" value="true" />
                          <button type="submit" id="${fallbackButtonId}"> </button>
                        </form> */}

                        {/* TODO: Make fallback work with PassportV1 (see above) */}
                        <div className='badge-subheader'>
                            <label className='badge-fallback-text anchor' htmlFor="fallbackButtonId"> {t(TranslationKeys.UI.BADGE.FALLBACK)} </label>
                        </div>

                        {/* TODO: Make badge submission work with PassportV1 */}
                        {/* We would need to parse out the values from the form object on the server */}
                        {/* <form id="badge-form" className="badge-form" autoComplete="off">
                            <input required className="badge-form-input" type="password" pattern="[0-9]*" inputMode="numeric" autoComplete="new-password" id="${badgeRFId}" name="badgeRFId" value={badgeRFId} maxLength={20} onInvalid={e => (e.target as HTMLInputElement).setCustomValidity(' ')} /> */}
                            {/* TODO: Delete the commented lines below once the above works */}
                            {/* <input type="hidden" name="${contextKey}" value="${ requestScope[contextKey] }" />
                            <input type="hidden" name="${authStep}" value="${ requestScope[authStep] }" />
                            <input type="hidden" name="${csrfName}" value="${csrfValue}" /> */}
                        {/* </form> */}

                    </div>
                    {/* end content-box */}

                    <Help />
                </div>
            </div>

            {/* TODO: Add idle timeout in affected screens under kiosk mode */}
            {KioskHelper.isKiosk() &&
                <LoginIdleTimeout />
            }

            <FaqModal />

            <LanguageModal />
        </>
    );
}
