import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import React from 'react';
import { Modal } from '@amzn/stencil-react-components/modal';
import { setVisible } from 'src/redux/pre-hire/pre-hire-faq-slice';
import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button';
import TranslationKeys from 'src/constants/translation-keys';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { H2 } from '@amzn/stencil-react-components/text';
import { IconCross } from '@amzn/stencil-react-components/icons';
import ERCFaqComponent from 'src/components/preHires/ERCFaqComponent';
import { METRIC_NAME } from "src/logger/loggerTypes";
import { COMPONENT_EVENT, COMPONENT_EVENT_RESULT } from "src/logger/loggerConstants";
import { Analytics } from '@aws-amplify/analytics';

export default function PreHireFaq() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { visible } = useAppSelector(state => state.preHireFaq);
    const login: string = useAppSelector(state => state.welcomeInfo.login);

    function closeModal() {
        dispatch(setVisible(false));
    }

    function openModal() {
        dispatch(setVisible(true));
        Analytics.record({
            name: METRIC_NAME.PREHIRE_GET_HELP_MODAL_CLICK,
            attributes: { username: login }
        });
    }

    return (
        <>
            <Button variant={ButtonVariant.Tertiary} size={ButtonSize.Small} data-testid="faqButtonERCFaq"
                    aria-describedby="sroDialog" onClick={openModal}>
                <div className="footer-text">
                    {t(TranslationKeys.FOOTER.GET_HELP)}
                </div>
            </Button>
            <Modal data-testid="faqModalERC" isOpen={visible} close={closeModal}>
                <div data-testid="faqTextERC" className="faq-text">
                    <Col
                        backgroundColor="neutral0"
                        gridGap="S500"
                        padding="S500"
                        alignItems="left">
                        <Row justifyContent="space-between">
                            <H2>{t(TranslationKeys.FAQ.FAQ_TITLE)}</H2>
                            <Button
                                icon={<IconCross/>}
                                variant={ButtonVariant.Tertiary}
                                onClick={closeModal}
                                data-testid="xIconButtonERCFaq"
                            />
                        </Row>
                        <ERCFaqComponent />
                        <Row>
                            <Button onClick={closeModal} data-testid="closeButtonERCFaq"
                                    variant={ButtonVariant.Primary}>
                                {t(TranslationKeys.UI.BUTTONS.CLOSE)}
                            </Button>
                        </Row>
                    </Col>
                </div>
            </Modal>
        </>
    )
}
