import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { InputWrapper, LabelPosition, Radio } from '@amzn/stencil-react-components/form';
import { Text } from '@amzn/stencil-react-components/text';
import { FlyoutContent, WithFlyout } from '@amzn/stencil-react-components/flyout';
import React, { useEffect, useState } from 'react';
import TranslationKeys from 'src/constants/translation-keys';
import { Col } from '@amzn/stencil-react-components/layout';
import I18nHelper from 'src/helpers/i18nHelper';
import CookieNames from 'src/constants/cookie-names';
import { Analytics } from '@aws-amplify/analytics';
import { useAppSelector } from "src/redux/hooks";
import { METRIC_NAME } from "src/logger/loggerTypes";
import CookieHelper from "src/helpers/cookie-helper";

const i18nConfig = require('../../../configuration/i18nConfig');

export default function LanguageSelectorFlyout() {
    const { t } = useTranslation();

    const [selectedLang, setSelectedLang] = useState<string | undefined>(undefined);
    const [userHasSeenFlyout, setUserHasSeenFlyout] = useState(false);
    const login: string = useAppSelector(state => state.welcomeInfo.login);

    function changeLanguage(value?: string) {
        i18n.changeLanguage( i18nConfig.langMap.get(value), () => {} ).then();
    }

    // Triggered when user closes our the flyout
    useEffect(() => {
        // If flyout was seen and language changed
        if (userHasSeenFlyout && selectedLang) {
            CookieHelper.setCookieWithPassportDomain(CookieNames.PREFERRED_LANGUAGE,
                i18nConfig.langMap.get(selectedLang).slice(0,2));
            CookieHelper.setCookieWithPassportDomain(CookieNames.PREFERRED_COUNTRY,
                i18nConfig.langMap.get(selectedLang).slice(-2));
            changeLanguage(selectedLang);
            Analytics.record({
            name: METRIC_NAME.PREHIRE_CHANGE_LANGUAGE_MODAL_CLICK,
            attributes: { username: login, selectedLanguage: selectedLang}});
        }
        // reset states
        setUserHasSeenFlyout(false);
        setSelectedLang(undefined);
    }, [userHasSeenFlyout]);


    const onClose = () => {
        setUserHasSeenFlyout(true);
    };

    // Get the list of languages available and display as radio buttons
    function renderLanguageList() {
        const selectedLangIndex = I18nHelper.getIndexOfSelectedLanguage();
        return (
            <Col gridGap="S300">
                <Text fontSize="T500">
                    {t(TranslationKeys.FOOTER.LANGUAGE.PREFERRED_LANGUAGE_HEADER)}
                </Text>
                { i18nConfig.langArray.map((val: string, index: number) => {
                        { Analytics.record({
                        name: METRIC_NAME.PREHIRE_DEFAULT_LANGUAGE,
                        attributes: { username: login, defaultLanguage: i18nConfig.langArray[I18nHelper.getIndexOfSelectedLanguage()]}}) }
                    return <InputWrapper
                        id={"radio-trailing" + index}
                        labelText={val}
                        key={"radio-wrapper" + index}
                        labelPosition={LabelPosition.Trailing}>
                        {inputProps => <Radio key={"radio" + index}
                                              name="radio"
                                              data-testid={ "languageSelectionRadio" + index }
                                              defaultChecked={ selectedLangIndex === index }
                                              onClick={ () => setSelectedLang(val) }
                                              {...inputProps} />}
                    </InputWrapper>
                }) }
            </Col>
        )
    }

    const renderFlyout = ({ close }: { close: () => void }) => (
        <FlyoutContent
            titleText={t(TranslationKeys.FOOTER.LANGUAGE.CHOOSE_LANGUAGE)}
            onCloseButtonClick={close}
            buttons={[
                <Button data-testid="language-flyout-close-button"
                        onClick={close}
                        key={'close-button-key'}
                        variant={ButtonVariant.Primary}>
                    {t(TranslationKeys.FOOTER.LANGUAGE.CHANGE_LANGUAGE)}
                </Button>
            ]}>
            { renderLanguageList() }
        </FlyoutContent>
    );

    return (
        <WithFlyout renderFlyout={renderFlyout} onClose={onClose}>
            {({open}) => (
                <>
                    <Button variant={ButtonVariant.Tertiary}
                            data-testid="language-flyout-button"
                            aria-describedby="sroDialog"
                            onClick={open}>
                        <div className="footer-text" data-testid="footer-text-translated">
                            {t(TranslationKeys.FOOTER.LANGUAGE.CHANGE_LANGUAGE)}
                        </div>
                    </Button>
                    <span hidden id="sroDialog">
                         has pop-up dialog
                    </span>
                </>
            )}
        </WithFlyout>
    );
}
