interface HTTPErrorResult {
    attribute?: string;
    statusCode: number;
    error: string | undefined;
}

export default class HTTPError extends Error {
    private _attribute?: string;
    private _statusCode: number;
    private _error: string | undefined;
    private _path: string;

    constructor(error: HTTPErrorResult, path: string) {
        super((error.attribute ? error.attribute + ': ' : '') + error.error);
        this._attribute = error.attribute;
        this._statusCode = error.statusCode;
        this._error = error.error;
        this._path = path;
    }

    get attribute() {
        return this._attribute;
    }

    get statusCode() {
        return this._statusCode;
    }

    get error() {
        return this._error;
    }

    get path() {
        return this._path;
    }

    public attributeMatches(attribute: string) : boolean {
        return (!!this.attribute) && attribute === this.attribute;
    }
}
