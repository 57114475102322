import React from "react";
import LanguageSelectorModal from './LanguageSelectorModal';
import { Label} from '@amzn/stencil-react-components/text';
import { Row, View, Container } from '@amzn/stencil-react-components/layout';
import PreHireFaq from 'src/components/preHires/PreHireFaq';
import LanguageSelectorFlyout from 'src/components/preHires/LanguageSelectorFlyout';

export default function Footer() {

    return (
        <Container>
            <hr />
            <Row gridGap="S500" alignItems="center" justifyContent="center" >
                <LanguageSelectorFlyout/>
                <View alignItems="center">
                    <Label textAlign="center">
                        .
                    </Label>
                </View>
                <PreHireFaq />
            </Row>
        </Container>
    );
}
