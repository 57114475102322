import React from 'react';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';

interface ErrorMessageBannerProps {
    errorMessage: string,
}

function ErrorMessageBanner(props: ErrorMessageBannerProps) {

    const { errorMessage } = props;

    return (
        <MessageBanner type={MessageBannerType.Error}>
            {errorMessage}
        </MessageBanner>
    );
}

export default ErrorMessageBanner;
