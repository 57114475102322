import React from "react";
import { useTranslation } from "react-i18next";
import TranslationKeys from "src/constants/translation-keys";
import I18nHelper from "src/helpers/i18nHelper";
import i18n from "i18next";

const i18nConfig = require('../../../configuration/i18nConfig');

export default function LanguageModal() {
    const { t } = useTranslation();
    const selectedSymbol = '>';

    function changeLanguage(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        let langName: string = (event.target as HTMLElement).innerText;
        if (langName.charAt(0) === selectedSymbol) {
            langName = langName.substring(1);
        }

        i18n.changeLanguage(
            i18nConfig.langMap.get(langName), () => {}
        ).then(() => {
            // TODO: Stop using JQuery. Migrate off of JQuery and use something like react-bootstrap.
            // @tungsmat is using JQuery now as to replicate the frontend migration as closely as possible.
            ($('#languageSelectionModal') as any).modal('hide');
            (document.activeElement! as HTMLElement).blur();
        });
    }

    return (
        <div className="modal justfade" id="languageSelectionModal" tabIndex={-1} role="dialog" aria-labelledby="languageSelectionModal_label">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <a href="javascript:void(0)" data-dismiss="modal" className="pull-right">
                            <div className="close-icon" data-dismiss="modal"></div>
                        </a>
                        <p className="modal-title" id="languageSelectionModal_label">{t(TranslationKeys.UI.HELP.CHOOSE_LANGUAGE)}</p>
                    </div>
                    <div className="modal-body">
                        {/* We need to create these as links for purposes of screen readers knowing */}
                        {/* what they'll do and handling the context change appropriately, but we */}
                        {/* actually do not need to post the data. As such, we'll create them as */}
                        {/* links here, but then intercept the link click event in React and perform */}
                        {/* a new language change within the browser instead. */}
                        <a className="selected change-language-link" href="javascript:void(0)" onClick={changeLanguage}>
                            {`${selectedSymbol}${I18nHelper.getSelectedLanguageName()}`}
                        </a>
                        {i18nConfig.langArray.map((language: string, index: number) => {
                            if (language != I18nHelper.getSelectedLanguageName()) {
                                return (
                                    <a key={index} href="javascript:void(0)" className="change-language-link" onClick={changeLanguage}>
                                        {language}
                                    </a>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
