const i18nConfig = require('../../configuration/i18nConfig');

export default class I18nHelper {

    // Gets the index of currently set language from the list of available languages
    public static getIndexOfSelectedLanguage(): number {
        return i18nConfig.localeArray.findIndex((element: string | null, index: number) => {
            // i18n sets an item in local storage to store the current set language
            return element === localStorage.getItem('i18nextLng');
        });
    }

    public static getSelectedLanguageName(): string {
        return i18nConfig.langArray[this.getIndexOfSelectedLanguage()];
    }
}
