import React, { useEffect, useState } from 'react';
import WelcomeContentView from './WelcomeContentView';
import Footer from './Footer';
import '../.././styles/prehires.scss'
import PreHireWelcomeRequest from 'src/@types/api/PreHireWelcomeRequest';
import { useLocation } from 'react-router';
import APIHelper from 'src/helpers/api-helper';
import CookieHelper from 'src/helpers/cookie-helper';
import CookieNames from 'src/constants/cookie-names';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import ErrorMessageBanner from 'src/components/preHires/ErrorMessageBanner';
import i18n from "i18next";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setPreferredName, setPasswordEventId, setLogin } from 'src/redux/pre-hire/welcome-info-slice'
import StringUtil from "src/util/StringUtil";
import katalTemplateString from 'src/util/helpers';
import Attributes from 'src/constants/attributes';
const i18nConfig = require('../../../configuration/i18nConfig');
import { getLogger } from "src/logger/logger";
import { METRIC_NAME } from "src/logger/loggerTypes";
import { COMPONENT_EVENT } from "src/logger/loggerConstants";
import { useTranslation } from 'react-i18next';
import TranslationKeys from "src/constants/translation-keys";
import { Analytics } from '@aws-amplify/analytics';

function changeLanguage(value?: string) {
    i18n.changeLanguage(i18nConfig.langMap.get(value), () => {
    }).then();
}

function WelcomeScreen() {
    const dispatch = useAppDispatch();
    const [rendering, setRendering] = useState(true);
    const [errorBannerIsShown, setErrorBannerIsShown] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const SESSION_QUERY_PARAMETER = 'session';
    const LANGUAGE_QUERY_PARAMETER = 'lang';
    const preferredName: string = useAppSelector(state => state.welcomeInfo.preferredName);
    const login: string = useAppSelector(state => state.welcomeInfo.login);

    const requestBody = createRequestFromQueryParam();

    const logger = getLogger();
    const { t } = useTranslation();

    function createRequestFromQueryParam() : PreHireWelcomeRequest | undefined {
        let apiRequestBody: PreHireWelcomeRequest | undefined;
        const querySearch = new URLSearchParams(useLocation().search);
        const session = querySearch.get(SESSION_QUERY_PARAMETER);
        const language = querySearch.get(LANGUAGE_QUERY_PARAMETER); // param not mandatory
        if (session) {
            apiRequestBody = {
                guid: session,
                passedLanguage: language === null ? 'en' : language
            };
        }
        return apiRequestBody;
    }

    function setLanguage(language: string | number | undefined) {
        // ASH only support two languages, en or es, so we will get either one or the other
        if (language && language as string === 'es') {
            changeLanguage('Español');
            Analytics.record({
                name: METRIC_NAME.PREHIRE_WELCOME_SCREEN_SPANISH,
                attributes: { username: login },
            });
        } else {
            changeLanguage('English');
        }
    }

    function fetchValuesFromStore() {
        if (!StringUtil.isEmpty(preferredName)) {
            setRendering(false);
            logger.info(`${COMPONENT_EVENT.PREHIRE_WELCOME_PAGE.PREFERRED_NAME}`, { username: login, preferredName: preferredName});
        } else {
            setErrorMessage(t(TranslationKeys.UI.PRE_HIRES.SESSION_INVALID_ERROR))
            setErrorBannerIsShown(true);
        }
    }

    // AtoZRedirectUrl will be replaced with corresponding url based on the environments at deploy time.
    const ATOZ_REDIRECT_URL = katalTemplateString('https://atoz.amazon.work/', Attributes.ATOZ_REDIRECT_DEV);

    // Redirecting to AtoZ if password already gained or account locked or disabled
    const redirectToAtoZ = () => {
        location.href = ATOZ_REDIRECT_URL;
    }

    function setPreferredCountryCookie(requestBody: PreHireWelcomeRequest) {
        if (requestBody.passedLanguage as string === 'es') {
            CookieHelper.setCookieWithPassportDomain(CookieNames.PREFERRED_COUNTRY, 'ES');
            Analytics.record({
                name: METRIC_NAME.PREHIRE_WELCOME_SCREEN_SPANISH,
                attributes: { username: login },
            });
        } else {
            CookieHelper.setCookieWithPassportDomain(CookieNames.PREFERRED_COUNTRY, 'US');
        }
    }

    function fetchUserData(retryAttempt: number) {
        if (requestBody && retryAttempt <= 3) {
            dispatch(setPasswordEventId(requestBody.guid));
            CookieHelper.setCookieWithPassportDomain(CookieNames.PREFERRED_LANGUAGE,
                requestBody.passedLanguage as string);
            setPreferredCountryCookie(requestBody);
            APIHelper.getPasswordGainEvent(requestBody)
                .then(response => {
                    dispatch(setLogin(response.login));
                    dispatch(setPreferredName(response.preferredName));
                    // we set language here instead of WelcomeContentView, as we want to the language to be set
                    // before WelcomeContentView renders
                    setLanguage(CookieHelper.getCookieValue(CookieNames.PREFERRED_LANGUAGE));
                    setRendering(false);
                })
                .catch(error => {
                    if (error.statusCode === 409 || error.statusCode === 404) {
                        // TODO: Work with UX to get proper experience
                        setErrorMessage(t(TranslationKeys.UI.PRE_HIRES.SESSION_INVALID_ERROR))
                        setErrorBannerIsShown(true);
                    } else if (error.statusCode === 401) { // password already gained or account locked
                        redirectToAtoZ();
                        return;
                    } else { // Retry
                        Analytics.record({
                            name: METRIC_NAME.PREHIRE_CSRF_ERROR_WELCOME_PAGE
                        });
                        fetchUserData(retryAttempt + 1);
                    }
                })
        } else { // in case user comes back to the welcome page without query parameters
            fetchValuesFromStore();
        }
    }

    useEffect(() => {
        logger.info(`${COMPONENT_EVENT.PREHIRE_WELCOME_PAGE.SCREEN_LOADING}`, { username: login }),
        Analytics.record({
            name: METRIC_NAME.PREHIRE_WELCOME_SCREEN_RENDER,
            attributes: { username: login },
        });
        fetchUserData(1);
    }, [])

    function checkSession() {
        return <>
            {errorBannerIsShown ? <ErrorMessageBanner errorMessage={errorMessage}/> :
                <Spinner fr={''} size={SpinnerSize.Medium}/>
            }
        </>
    }

    return (
        <div className="fullScreen">
            <div className="row-flex-container">
                <div data-testid="welcomeImage" className="flex-box-left-image">
                </div>
                <div className="flex-box-right-image">
                    <div data-testid="welcomeContent" className="content-wrap">
                        {rendering ? checkSession() : <WelcomeContentView login={preferredName} />}
                    </div>
                    <div data-testid="footer" className="footer-container">
                        <Footer/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WelcomeScreen;
