import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TranslationKeys from "src/constants/translation-keys";
import AssetsLogo from '@amzn/passport-v1ui/dist/assets_logo_blue.svg';
import '../.././styles/prehires.scss'
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Col, Row, View } from '@amzn/stencil-react-components/layout';
import { DetailedRadio } from '@amzn/stencil-react-components/form';
import { Link } from '@amzn/stencil-react-components/link';
import { useNavigate } from 'react-router';
import ReactRoutes from 'src/constants/react-routes';
import GetContactInfoInput from 'src/@types/api/GetContactInfoInput';
import APIHelper from 'src/helpers/api-helper';
import ErrorMessageBanner from 'src/components/preHires/ErrorMessageBanner';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import SelectedOTPDestination from 'src/@types/api/SelectedOTPDestination';
import ApiError from 'src/errors/api-error';
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setSelectedOtp, setSelectedOtpIndex } from "src/redux/pre-hire/otp-slice";
import StringUtil from "src/util/StringUtil";
import { setVisible } from 'src/redux/pre-hire/pre-hire-faq-slice';
import { getLogger } from "src/logger/logger";
import { COMPONENT_EVENT, COMPONENT_EVENT_RESULT } from "src/logger/loggerConstants";

export default function OtpDestinationContentView() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [rendering, setRendering] = useState(false);
    const [sendingOtp, setSendingOtp] = useState(false);
    const [emailList, setEmailList] = useState<string[] | undefined>(undefined);
    const [phoneList, setPhoneList] = useState<string[] | undefined>(undefined);
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
    const [userSelectedOTP, setUserSelectedOTP] = useState<string | undefined>(undefined);
    const [errorBannerIsShown, setErrorBannerIsShown] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const login: string = useAppSelector(state => state.welcomeInfo.login);
    const eventGuid: string = useAppSelector(state => state.welcomeInfo.passwordEventId);
    const logger = getLogger();

    function createGetOTPRequest(): GetContactInfoInput | undefined {
        if (!StringUtil.isEmpty(login) && !StringUtil.isEmpty(eventGuid)) {
            return { login, eventGuid }
        }
        return undefined;
    };

    // Maps the obfuscated emails we received from API into HTML elements
    function getEmailDivs() {
        return emailList?.map((value, index) => {
            return <DetailedRadio
                key={index}
                data-testid={'email-' + index}
                name="otp-radio"
                value={"email-"+ index}
                titleText={t(TranslationKeys.UI.PRE_HIRES.OTP_DESTINATION_EMAIL, { 0: value})}
                onChange={() => {
                    // SMS first in the list, so we add phone list size to the selected index for email selection
                    setSelectedIndex(phoneList === undefined ? 0 : phoneList.length + index + 1);
                    setUserSelectedOTP(value);
                }}
            />
        })
    };

    // Maps the obfuscated phones we received from API into HTML elements
    function getPhoneDivs() {
        return phoneList?.map((value, index) => {
            return <DetailedRadio
                key={index}
                data-testid={'phone-' + index}
                name="otp-radio"
                disabled={false}
                value={"phone-"+ index}
                titleText={t(TranslationKeys.UI.PRE_HIRES.OTP_DESTINATION_SMS, { 0: value})}
                onChange={() => {
                    setSelectedIndex(index + 1);
                    setUserSelectedOTP(value);
                }}
            />
        })
    };

    // Fetch OTP destination
    useEffect(() => {
        setRendering(true);
        const request = createGetOTPRequest();
        if (request) {
            APIHelper.getOtpDestinations(request)
                .then(response => {
                    setPhoneList(response.obfuscatedPhones)
                    setEmailList(response.obfuscatedEmails);
                    setRendering(false);
                })
                .catch(error => {
                    // TODO: Proper error handling
                    if (error.statusCode === 409) {
                        setErrorMessage(t(TranslationKeys.UI.PRE_HIRES.SESSION_EXPIRED_ERROR))
                        setErrorBannerIsShown(true);
                    } else {
                        setErrorMessage(t(TranslationKeys.UI.PRE_HIRES.UNEXPECTED_ERROR));
                        setErrorBannerIsShown(true);
                    }
                });
        } else { // couldn't form request from cookies
            // TODO : handle the case when cookies doesn't exist to form request
            setErrorMessage(t(TranslationKeys.UI.PRE_HIRES.UNEXPECTED_ERROR))
            setErrorBannerIsShown(true);
        }
    }, []);

    function createSendResetPinAPIRequest() : SelectedOTPDestination | undefined {
        if (!StringUtil.isEmpty(login) && !StringUtil.isEmpty(eventGuid) && selectedIndex) {
            return { login , eventGuid, selectedIndex }
        }
        return undefined;
    }

    // When user clicks back button, navigate to welcome page
    function handleBackButtonClick() {
        navigate(ReactRoutes.PREHIRES_WELCOME);
    }

    function handleSendCodeButtonClick() {
        setSendingOtp(true);
        const sendPinApiRequest = createSendResetPinAPIRequest();
        if (sendPinApiRequest) {
            APIHelper.sendOTPToSelectedDestination(sendPinApiRequest)
                .then(response => {
                    if (selectedIndex && userSelectedOTP) { // store the selected index and its value
                        dispatch(setSelectedOtp(userSelectedOTP));
                        dispatch(setSelectedOtpIndex(selectedIndex));
                    }
                    setSendingOtp(false);
                    navigate(ReactRoutes.PREHIRES_TOKEN_AND_PASSWORD);
                })
                .catch(error => {
                    setSendingOtp(false);
                    // TODO: Proper error handling
                    logger.error(`${COMPONENT_EVENT.PREHIRE_OTP_PAGE.SEND_CODE_BUTTON} ${COMPONENT_EVENT_RESULT.FAILED}`, error, { username: login });
                    if (error instanceof ApiError) {
                        if (error.statusCode === 503) {
                            setErrorMessage(t(TranslationKeys.UI.PRE_HIRES.OTP_RESEND_TOO_MANY_ATTEMPTS));
                            setErrorBannerIsShown(true);
                        } else if (error.statusCode === 409) {
                            setErrorMessage(t(TranslationKeys.UI.PRE_HIRES.SESSION_INVALID_ERROR))
                            setErrorBannerIsShown(true);
                        } else if (error.statusCode === 401) {
                            setErrorMessage(t(TranslationKeys.UI.PRE_HIRES.OTP_UNAUTHORIZED_ERROR))
                            setErrorBannerIsShown(true);
                        } else {
                            setErrorMessage(t(TranslationKeys.UI.PRE_HIRES.GENERIC_ERROR))
                            setErrorBannerIsShown(true);
                        }
                        // show the error banner for 5s
                        setInterval(() => {
                            setErrorBannerIsShown(false);
                        }, 5000)
                    }
                })
        } else {
            setSendingOtp(false);
            // TODO: Proper error handling
            setErrorMessage(t(TranslationKeys.UI.PRE_HIRES.OTP_GENERIC_ERROR))
            setErrorBannerIsShown(true);
            logger.error(`${COMPONENT_EVENT.PREHIRE_OTP_PAGE.SEND_CODE_BUTTON} ${COMPONENT_EVENT_RESULT.FAILED}`, { username: login });
        }
    }

    return (
        <View className="prehires-hero-content" >
            <Col gridGap="S300" >
                <View className="logo">
                    <img src={AssetsLogo}/>
                </View>
                <View className="header">
                    <div data-testid="otpDestinationHeader"> {t(TranslationKeys.UI.PRE_HIRES.OTP_DESTINATION_HEADER)} </div>
                </View>
                <View className="description">
                    <div data-testid="otpDestinationBody"> {t(TranslationKeys.UI.PRE_HIRES.OTP_DESTINATION_DESCRIPTION)} </div>
                </View>
                <Col gridGap="S300">
                    { rendering ?
                        <Spinner fr={""} size={SpinnerSize.Medium} /> :
                        <Col gridGap="S200">
                            {getPhoneDivs()}
                            {getEmailDivs()}
                        </Col>
                    }
                </Col>
                <Link target="_blank" onClick={() => {
                    logger.info(`${COMPONENT_EVENT.PREHIRE_OTP_PAGE.NONE_OF_THESE_WORK_LINK}`, { username: login });
                    dispatch(setVisible(true))}}>
                    {t(TranslationKeys.UI.PRE_HIRES.OTP_DESTINATION_NONE_WORK)}
                </Link>
                <Row gridGap="S500">
                    {sendingOtp ? <Spinner fr={""} size={SpinnerSize.Small}/> :
                        <>
                            <Button data-testid="backButton" style={{width: '50%'}}
                                  onClick={() => {logger.info(`${COMPONENT_EVENT.PREHIRE_OTP_PAGE.BACK_BUTTON}`, { username: login });
                                    handleBackButtonClick()}}>
                            {t(TranslationKeys.UI.BUTTONS.BACK)}
                            </Button>
                            <Button data-testid="sendCodeButton" disabled={selectedIndex === undefined}
                                         onClick={() => {logger.info(`${COMPONENT_EVENT.PREHIRE_OTP_PAGE.SEND_CODE_BUTTON}`, { username: login });
                                         handleSendCodeButtonClick()}}
                                         variant={ButtonVariant.Primary} style={{width: '50%'}}>
                            {t(TranslationKeys.UI.BUTTONS.SEND_CODE)}
                            </Button>
                        </>
                    }
                </Row>
                <View>
                    {errorBannerIsShown && <ErrorMessageBanner errorMessage={errorMessage} />}
                </View>
            </Col>
        </View>
    );
}
