import React from 'react';
import Footer from './Footer';
import EnterOtpContentView from './EnterOtpContentView';

function EnterOtpScreen(){
    return (
        <div className="fullScreen">
            <div className="row-flex-container">
                <div data-testid="enterOtpImageBackground" className="flex-box-left-badge-background">
                </div>
                <div className="flex-box-right">
                    <div data-testid="enterOtpContent" className="content-wrap">
                        <EnterOtpContentView/>
                    </div>
                    <div data-testid="footer" className="footer-container">
                        <Footer/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EnterOtpScreen;