import Cookies from 'js-cookie';

export default class CookieHelper {

    private static DEFAULT_EXPIRATION_TIME: number = 30; // 30 mins

    private static COOKIE_MAX_AGE: number = 10512000; // The max age of a cookie, 20 years.

    private static mapExpirationTime(timeInMins: number) : Date {
        return new Date(new Date().getTime() + timeInMins * 60 * 1000);
    }

    // Creates a new cookie with given expiration time
    public static createCookieWithExpiration(name: string, value: string, expiryInMins: number) : void {
        Cookies.set(name, value, {
            expires: this.mapExpirationTime(expiryInMins)
        });
    }

    // Creates a new cookie with a default time of 30 mins
    public static createCookie(name: string, value: string) : void {
        CookieHelper.createCookieWithExpiration(name, value, CookieHelper.DEFAULT_EXPIRATION_TIME);
    }

    // Sets a cookie with max age
    public static setCookieWithMaxAge(name: string, value: string) : void {
        CookieHelper.createCookieWithExpiration(name, value, CookieHelper.COOKIE_MAX_AGE);
    }

    // Fetches give cookie's value
    public static getCookieValue(name: string) : string | number | undefined {
        return Cookies.get(name);
    }

    public static getDomain(): string {
        const host = location.hostname;
        // https://tiny.amazon.com/1i24jyl1z/codeamazpackIdPFbloba3a5src
        // Using same logic as IdPFilters#WebHelper#getRequestDomain
        return host.replace(new RegExp(".*\\.(?=.*\\.)"), "");
    }

    public static setCookieWithPassportDomain(name: string, value: string) {
        const expiryTime = this.mapExpirationTime(CookieHelper.COOKIE_MAX_AGE);
        const domain = this.getDomain();
        const secure = !domain.includes("localhost");
        Cookies.set(name, value, {
            path: "/",
            domain: this.getDomain(),
            secure: secure,
            expires: expiryTime
        })
    }
}
