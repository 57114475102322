export const COMPONENT_EVENT = {
    PREHIRE_WELCOME_PAGE: {
        SCREEN_LOADING: 'Welcome screen is loading',
        DISPLAYED_IN_SPANISH: 'Welcome page is rendered in Spanish',
        PREFERRED_NAME: 'Showing preferred name for user',
        CONTINUE_BUTTON: 'Continue button is clicked',
        PREFERRED_LANGUAGE: 'Preferred language modal is open',
    },
    PREHIRE_OTP_PAGE: {
        SCREEN_LOADING: 'OTP destination screen is loading',
        NONE_OF_THESE_WORK_LINK: 'None of these work link is clicked, showing ERC contacts',
        BACK_BUTTON: 'Back button is clicked, taking user to welcome page',
        SEND_CODE_BUTTON: 'Send code button is clicked',
    },
    PREHIRE_PASSWORD_PAGE: {
        SCREEN_LOADING: 'Enter reset pin and create password screen is loading',
        RESEND_CODE_LINK: 'Resend code button is clicked',
        SEND_CODE_ANOTHER_WAY_LINK: 'Send code another way button is clicked',
        BACK_BUTTON: 'Back button is clicked, taking user to OTP destination page',
        CREATE_PASSWORD_BUTTON: 'Create password button is clicked',
    },
    PREHIRE_DOWNLOAD_APP_PAGE: {
        SCREEN_LOADING: 'Download app screen is loading',
        START_ATOZ_BUTTON: 'Start AtoZ button is clicked, redirecting user to AtoZ page',
    },
    PREHIRE_FOOTER: {
        CHANGE_LANGUAGE_MODAL: 'Change language modal',
        GET_HELP_MODAL: 'Get help modal',
    },
};

export enum COMPONENT_EVENT_RESULT {
    SUCCESSFUL = 'successful',
    FAILED = 'failed',
    CLOSED = 'closed',
}
