import Constants from "src/constants";
import IdPRoutes from "src/constants/idp-routes";

export default function katalTemplateString(template: string, defaultUrl: string) {
    if (template.includes('{{') && template.includes('}}')) {
        return defaultUrl;
    }
    return template;
}

export function redirectToAtoZWebProd(): void {
    window.location.replace(Constants.atoz_web_prod_url);
}

export function isDev(): boolean {
    return process.env.NODE_ENV == 'development'
}

export function isIdPRoute(path: string | undefined) {
    if (!path) {
        return false;
    }

    const paths: string[] = Object.values(IdPRoutes);
    return paths.includes(path);
}