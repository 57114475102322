export enum METRIC_NAME {
    PREHIRE_WELCOME_SCREEN_RENDER = 'prehire.welcomeScreen.render',
    PREHIRE_WELCOME_SCREEN_SPANISH = 'prehire.welcomeScreen.spanish',
    PREHIRE_WELCOME_CONTINUE_BUTTON_CLICK = 'prehire.welcome.continueButton.click',
    PREHIRE_WELCOME_PREFERRED_LANGUAGE_MODAL_CLICK = 'prehire.welcome.preferredLangModal.click',
    PREHIRE_OTP_SCREEN_RENDER = 'prehire.otpScreen.render',
    PREHIRE_OTP_NONE_OF_THESE_WORK_LINK_CLICK = 'prehire.otp.noneOfTheseWorkLink.click',
    PREHIRE_OTP_BACK_BUTTON_CLICK = 'prehire.otp.backButton.click',
    PREHIRE_OTP_SEND_CODE_BUTTON_CLICK = 'prehire.otp.sendCodeButton.click',
    PREHIRE_OTP_SEND_CODE_FAILURE = 'prehire.password.sendCode.failure',
    PREHIRE_PASSWORD_SCREEN_RENDER = 'prehire.passwordScreen.render',
    PREHIRE_PASSWORD_RESEND_CODE_LINK_CLICK = 'prehire.password.resendCodeLink.click',
    PREHIRE_PASSWORD_SEND_CODE_ANOTHER_WAY_LINK_CLICK = 'prehire.password.sendCodeAnotherWayLink.click',
    PREHIRE_PASSWORD_BACK_BUTTON_CLICK = 'prehire.password.backButton.click',
    PREHIRE_PASSWORD_CREATE_PASSWORD_BUTTON_CLICK  = 'prehire.password.createPasswordButton.click',
    PREHIRE_PASSWORD_CREATE_PASSWORD_FAILURE = 'prehire.password.createPassword.failure',
    PREHIRE_DOWNLOAD_APP_SCREEN_RENDER = 'prehire.downloadAppScreen.render',
    PREHIRE_DOWNLOAD_APP_START_ATOZ_BUTTON_CLICK = 'prehire.downloadApp.startAtoZButton.click',
    PREHIRE_CHANGE_LANGUAGE_MODAL_CLICK = 'prehire.changeLanguageModal.click',
    PREHIRE_GET_HELP_MODAL_CLICK = 'prehire.getHelpModal.click',
    PREHIRE_DEFAULT_LANGUAGE = 'prehire.defaultLanguage.metric',
    PREHIRE_CSRF_ERROR_WELCOME_PAGE = 'prehire.csrf.error.retry',
}