export default class CookieNames {
    public static readonly LOGIN = 'login';
    public static readonly PREFERRED_NAME = 'name';
    public static readonly PASSWORD_EVENT_ID = 'gain_session_id';
    public static readonly SELECTED_OTP_INDEX = 'selected-otp-index';
    public static readonly SELECTED_OTP = 'selected-otp';
    public static readonly API_CONTEXT = 'amzn-idp-api-context';
    public static readonly PREFERRED_LANGUAGE = 'amzn-idp-pref-lang';
    public static readonly PREFERRED_COUNTRY = 'amzn-idp-pref-country';
    public static readonly KIOSK_DEVICE = 'amzn-idp-login-kiosk';
}
