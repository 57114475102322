import React, { useEffect } from 'react';
import '../.././styles/prehires.scss'
import { Col, Hr, View } from '@amzn/stencil-react-components/layout';
import EnterOtpComponent from "src/components/preHires/EnterOtpComponent";
import PasswordRulesComponent from "src/components/preHires/PasswordRulesComponent";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import ErrorMessageBanner from "src/components/preHires/ErrorMessageBanner";
import { resetCreatePasswordStates, setSubmitted } from "src/redux/pre-hire/create-password-slice";
import PasswordSubmissionComponent from "src/components/preHires/PasswordSubmissionComponent";
import TranslationKeys from 'src/constants/translation-keys';
import { useTranslation } from 'react-i18next';
import PasswordInputComponent from './PasswordInputComponent';
import { resetPasswordStates } from 'src/redux/pre-hire/password-slice';
import { resetEnteredOtpData } from 'src/redux/pre-hire/otp-slice';

export default function EnterTokenAndPasswordContentView() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { charLimitCheck, specialCharCheck, passwordMatch } = useAppSelector(state => state.passwordInput);
    const { otpLengthCheck } = useAppSelector(state => state.otp);
    const { submitted, passwordApiError, resetPinApiError } = useAppSelector(state => state.createPasswordState);

    function checkApiError() {
        if (passwordApiError || resetPinApiError) {
            return <ErrorMessageBanner errorMessage={t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_GLOBAL_ERROR1)}/>;
        }
    }

    // Clear states on unmount. Reset password input fields and checks, along with reset pin input data.
    // Will get triggered on browser back/forward button and any navigation to other react routes
    useEffect(() => {
        return () => {
            dispatch(resetPasswordStates());
            // only clear entered opt data, as we don't want to force the user to send another otp to go from otp page
            // to create password page
            dispatch(resetEnteredOtpData());
            dispatch(resetCreatePasswordStates());
        }
    }, [])

    useEffect(() => {
        // Dismiss submission error banner iff all the dynamic checks pass
        if (submitted && charLimitCheck && specialCharCheck && otpLengthCheck && passwordMatch) {
            dispatch(setSubmitted(false));
        }
    }, [charLimitCheck, specialCharCheck, passwordMatch, otpLengthCheck]);


    function checkValidationErrors() {
        // if submit button clicked and dynamic checks are failing
        if (submitted && !(charLimitCheck && specialCharCheck && passwordMatch && otpLengthCheck)) {
            return <ErrorMessageBanner errorMessage={t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_GLOBAL_ERROR1)}/>;
        }
    }

    return (
        <View className="prehires-hero-content" data-testid="main">
            <Col gridGap="S400">
                { checkValidationErrors() }
                { checkApiError() }
                <EnterOtpComponent />
                <Hr size={"wide"} />
                <PasswordRulesComponent />
                <PasswordInputComponent />
                <PasswordSubmissionComponent/>
            </Col>
        </View>
    );
}
