import React from 'react';
import Footer from './Footer';
import CreatePasswordContentView from './CreatePasswordContentView';

function CreatePasswordScreen() {

    return (
        <div className="fullScreen">
            <div data-testid="createPasswordImageBackground" className="row-flex-container">
                <div className="flex-box-left-lock-background">
                </div>
                <div className="flex-box-right">
                    <div data-testid="createPasswordContent" className="content-wrap">
                        <CreatePasswordContentView/>
                    </div>
                    <div data-testid="footer" className="footer-container">
                        <Footer/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreatePasswordScreen;