import { useTranslation } from "react-i18next";
import { Col, View } from "@amzn/stencil-react-components/layout";
import AssetsLogo from '@amzn/passport-v1ui/dist/assets_logo_blue.svg';
import TranslationKeys from "src/constants/translation-keys";
import { Input } from "@amzn/stencil-react-components/form";
import { Text } from '@amzn/stencil-react-components/text';
import { Link } from "@amzn/stencil-react-components/link";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import React, { useEffect, useState } from "react";
import { setEnteredOtp, setOtpLengthCheck } from "src/redux/pre-hire/otp-slice";
import SelectedOTPDestination from "src/@types/api/SelectedOTPDestination";
import APIHelper from "src/helpers/api-helper";
import ApiError from "src/errors/api-error";
import ErrorMessageBanner from "src/components/preHires/ErrorMessageBanner";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { useNavigate } from "react-router";
import ReactRoutes from "src/constants/react-routes";
import StringUtil from "src/util/StringUtil";
import { setResetPinApiError } from "src/redux/pre-hire/create-password-slice";
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { getLogger } from "src/logger/logger";
import { METRIC_NAME } from "src/logger/loggerTypes";
import { COMPONENT_EVENT } from "src/logger/loggerConstants";
import { Analytics } from '@aws-amplify/analytics';

export default function EnterOtpComponent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [resendOtpErrorMessage, setResendOtpErrorMessage] = useState<string | undefined>(undefined);
    const [resendOtpSuccessMessage, setResendOtpSuccessMessage] = useState<string | undefined>(undefined);
    // To show a spinner icon when user clicks to resend pin
    const [rendering, setRendering] = useState(false);
    const { enteredOtp, selectedOtpIndex, otpLengthCheck, selectedOtp } = useAppSelector(state => state.otp);
    const { login, passwordEventId } = useAppSelector(state => state.welcomeInfo);
    const { submitted, resetPinApiError } = useAppSelector(state => state.createPasswordState);
    const logger = getLogger();

    // Banner to display any error happened during resending password reset token
    function showOtpResendErrorBanner() {
        if (resendOtpErrorMessage) {
            return <MessageBanner type={ MessageBannerType.Error }
                                  isDismissible={ true }
                                  onDismissed={ () => setResendOtpErrorMessage(undefined) }>
                {resendOtpErrorMessage}
            </MessageBanner>;
        }
    }

    // Resend password token success message banner
    function showOtpResendSuccessBanner() {
        if (resendOtpSuccessMessage) {
            return (<MessageBanner type={MessageBannerType.Success}
                                   isDismissible={true}
                                   onDismissed={() => setResendOtpSuccessMessage(undefined)}>
                {resendOtpSuccessMessage}
            </MessageBanner>);
        }
    }

    // Clear out banners when user starts typing
    function clearBanners() {
        // clear if resend pin success message banner shown
        if (resendOtpSuccessMessage ) {
            setResendOtpSuccessMessage(undefined);
        }
    }

    // Method to check length on input box de-focus
    function otpSizeCheck(enteredOtp: string) {
        dispatch(setEnteredOtp(enteredOtp));
        // Clear incorrect pin error banner
        dispatch(setResetPinApiError(undefined));
        if (enteredOtp.length === 8) {
            dispatch(setOtpLengthCheck(true));
        } else { // token length other than 0 or 8
            dispatch(setOtpLengthCheck(false));
        }
    }

    // Button to go back to OTP destination page, in case user wants to send pin to another destination
    function handleGoBack() {
        navigate(ReactRoutes.PREHIRES_OTP_DESTINATION);
    }

    useEffect(() => {
        // If create password button clicked and the OTP input field is empty
        if (submitted && enteredOtp.length === 0) {
            dispatch(setOtpLengthCheck(false));
        }
    }, [submitted])

    // Resend password reset token
    function handleResendToken() {
        setRendering(true);
        if (selectedOtpIndex != -1 && !StringUtil.isEmpty(login) && !StringUtil.isEmpty(passwordEventId)) {
            const sendResetPinRequest: SelectedOTPDestination = { login, eventGuid: passwordEventId,
                selectedIndex: selectedOtpIndex };
            // clear out the previous banners
            setResendOtpErrorMessage(undefined);
            setResendOtpSuccessMessage(undefined);
            APIHelper.sendOTPToSelectedDestination(sendResetPinRequest)
                .then(response => {
                    setResendOtpSuccessMessage(t(TranslationKeys.UI.PRE_HIRES.OTP_RESEND_SUCCESS));
                    setRendering(false);
                }).catch(error => {
                    setRendering(false);
                    if (error instanceof ApiError && error.statusCode === 503) { // Too many attempts
                        setResendOtpErrorMessage(t(TranslationKeys.UI.PRE_HIRES.OTP_RESEND_TOO_MANY_ATTEMPTS));
                    } else { // TODO: handle explicitly 409: Session invalid, 401: User unauthorized to reset pin
                        setResendOtpErrorMessage(t(TranslationKeys.UI.PRE_HIRES.OTP_RESEND_EXCEPTION));
                    }
                }
            );
        }
    }

    // Show message "We sent an email" or "We sent an SMS" based on OTP destination type
    function verificationText() {
        if (selectedOtp.includes('@')) {
            return  t(TranslationKeys.UI.PRE_HIRES.OTP_VERIFICATION_DESCRIPTION1, {0: selectedOtp});
        }
        return t(TranslationKeys.UI.PRE_HIRES.OTP_VERIFICATION_DESCRIPTION2, {0: selectedOtp});
    }

    // Show error banner if OTP length check failed when submit button clicked or entered otp length is non-zero
    function otpLengthCheckErrorBanner() {
        if ((submitted || enteredOtp.length !== 0) && !otpLengthCheck) {
            return <ErrorMessageBanner errorMessage={t(TranslationKeys.UI.PRE_HIRES.OTP_LENGTH_CHECK_FAIL)}/>;
        }
    }

    // If backend returns "Invalid token" during password creation
    function showApiError() {
        if (resetPinApiError && resetPinApiError.length > 0) {
            return <ErrorMessageBanner errorMessage={resetPinApiError}/>;
        }
    }

    return (
        <div data-testid="enter-otp-div-test-id">
            <Col gridGap="S300">
                { showOtpResendErrorBanner() }
                { showOtpResendSuccessBanner() }
                <View className="logo">
                    <img src={AssetsLogo}/>
                </View>
                <View>
                    <Text fontSize="T500" fontWeight={"bold"} shouldUseFixedFontStyle={true}>
                        {t(TranslationKeys.UI.PRE_HIRES.OTP_AND_PASSWORD_HEADER)}
                    </Text>
                </View>
                <View className="label-text" data-testid="verification-text">
                    <Text fontSize="T300">
                        { verificationText() }
                    </Text>
                </View>
                <View className="input-with-error-banner">
                    <Input
                        style={{fontSize: "small"}}
                        name="reset-token-input"
                        data-testid="otp-token"
                        placeholder={t(TranslationKeys.UI.PRE_HIRES.OTP_AND_PASSWORD_LABEL)}
                        onBlur={(event) => otpSizeCheck(event.target.value)}
                        onChange={() => clearBanners()}
                    />
                    { otpLengthCheckErrorBanner() }
                    { showApiError() }
                </View>
                {rendering ? <Spinner data-testid="spinner-test-id" fr={""} size={SpinnerSize.Small}/> :
                    <Link data-testid="resend-otp-link" onClick={() => {
                        logger.info(`${COMPONENT_EVENT.PREHIRE_PASSWORD_PAGE.RESEND_CODE_LINK}`,  { username: login });
                        Analytics.record({
                            name: METRIC_NAME.PREHIRE_PASSWORD_RESEND_CODE_LINK_CLICK,
                            attributes: { username: login },
                        });
                        handleResendToken()}}>
                        {t(TranslationKeys.UI.BUTTONS.RESEND_CODE)}
                    </Link>
                }
                <Link data-testid="send-code-another-way" onClick={() =>  {
                    logger.info(`${COMPONENT_EVENT.PREHIRE_PASSWORD_PAGE.SEND_CODE_ANOTHER_WAY_LINK}`, { username: login });
                    Analytics.record({
                        name: METRIC_NAME.PREHIRE_PASSWORD_SEND_CODE_ANOTHER_WAY_LINK_CLICK,
                        attributes: { username: login },
                    });
                    handleGoBack()}}>
                    {t(TranslationKeys.UI.PRE_HIRES.OTP_SEND_CODE_ANOTHER_WAY)}
                </Link>
            </Col>
        </div>);
}

