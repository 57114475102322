import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isAndroid, isIOS } from 'react-device-detect';
import AssetsLogo from '@amzn/passport-v1ui/dist/assets_logo_blue.svg';
import AppleStore from '@amzn/passport-v1ui/dist/Apple_EN.svg';
import GoogleStore from '@amzn/passport-v1ui/dist/Google_EN.svg';
import AppQrCode from '@amzn/passport-v1ui/dist/atozapp_qrcode.png';
import '../.././styles/prehires.scss'
import TranslationKeys from "src/constants/translation-keys";
import Attributes from "src/constants/attributes";
import katalTemplateString from "src/util/helpers";
import { useAppSelector } from "src/redux/hooks";
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Col, Row, Container, View } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { getLogger } from "src/logger/logger";
import { METRIC_NAME } from "src/logger/loggerTypes";
import { COMPONENT_EVENT } from "src/logger/loggerConstants";
import { Analytics } from '@aws-amplify/analytics';


export default function DownloadAppView() {
    const logger = getLogger();
    const { t } = useTranslation();
    const login: string = useAppSelector(state => state.welcomeInfo.login);

    function Downloads(link:string, imgSrc:string, imgAlt:string) {
      return (
        <Col gridGap="S300">
          <div className="label-text">{t(TranslationKeys.UI.PRE_HIRES.DOWNLOAD_APP_DESCRIPTION_MOBILE)}</div>
          <a data-testid="link" href={link}><img src={imgSrc} alt={imgAlt} /></a>
        </Col>
      );
    }


    // AtoZRedirectUrl will be replaced with corresponding url based on the environments at deploy time.
    const ATOZ_REDIRECT_URL = katalTemplateString('https://atoz.amazon.work/', Attributes.ATOZ_REDIRECT_DEV);
    // Using button to redirect, not ideal but following UX mockups. Can't style it link as a button as we are using
    // stencil components
    const redirectToAtoZ = () => {
        location.href = ATOZ_REDIRECT_URL;
    }

    function DownloadsGuide() {
        if (isAndroid) {
            return Downloads(Attributes.GOOGLE_STORE_LINK, GoogleStore, 'Google App Store');
        }
        if (isIOS) {
            return  Downloads(Attributes.APPLE_STORE_LINK, AppleStore, 'Apple App Store');
        }
        return (
             <Container alignItems="center" className="app-downloads-container">
                <Container className="qr-container" backgroundColor="neutral05" >
                    <Row gridGap="S200">
                       <div className="qr-img">
                            <img src={AppQrCode}/>
                        </div>
                        <div className="qr-text">
                            {t(TranslationKeys.UI.PRE_HIRES.DOWNLOAD_APP_DESCRIPTION)}
                       </div>
                    </Row>
                </Container>
                <Row className="store-logo" gridGap="S500">
                     <img src={AppleStore} alt="Apple App Store"/>
                     <img src={GoogleStore} alt="Google App Store"/>
                </Row>
             </Container>
        );
    }

    return (
        <View className="prehires-hero-content" >
            <Col gridGap="S300">
                <View className="logo">
                    <img src={AssetsLogo}/>
                </View>
                <View data-testid="downloadAppHeader" className="header">
                    <div> {t(TranslationKeys.UI.PRE_HIRES.DOWNLOAD_APP_HEADER)} </div>
                </View>
                <Col gridGap="S200">
                    <View data-testid="downloadAppLoginTitle" className="app-downloads-title">
                        <div> {t(TranslationKeys.UI.PRE_HIRES.DOWNLOAD_APP_LOGIN_DETAILS_TITLE)} </div>
                    </View>
                    <View data-testid="downloadAppLoginBody" className="app-downloads-body">
                        <div> {t(TranslationKeys.UI.PRE_HIRES.DOWNLOAD_APP_LOGIN_DETAILS_BODY)} </div>
                    </View>
                </Col>
                <Col gridGap="S200">
                    <View data-testid="downloadAppLoginText" className="app-downloads-login">
                        <div> {t(TranslationKeys.UI.PRE_HIRES.DOWNLOAD_APP_LOGIN_TEXT)} </div>
                    </View>
                    <View data-testid="downloadAppUserLogin" className="description">
                        <div> {login} </div>
                    </View>
                </Col>
                <View data-testid="downloadAppGuide" className="app-downloads-title">
                    <div> {t(TranslationKeys.UI.PRE_HIRES.DOWNLOAD_APP_GUIDE)} </div>
                </View>
                <DownloadsGuide />
                <View alignSelf='center' width="100%">
                    <Button variant={ButtonVariant.Primary} data-testid="startAtoZButton"
                    onClick={() => {
                            logger.info(`${COMPONENT_EVENT.PREHIRE_DOWNLOAD_APP_PAGE.START_ATOZ_BUTTON}`, { username:login });
                            Analytics.record({
                                name: METRIC_NAME.PREHIRE_DOWNLOAD_APP_START_ATOZ_BUTTON_CLICK,
                                attributes: { username: login },
                                });
                            redirectToAtoZ();
                    }} style={{width : '100%'}}>
                        {t(TranslationKeys.UI.BUTTONS.START_ATOZ)}
                    </Button>
                </View>
            </Col>
        </View>
    );
}
