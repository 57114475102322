import { useTranslation } from 'react-i18next';
import React from 'react';
import TranslationKeys from 'src/constants/translation-keys';
import { Col, View } from '@amzn/stencil-react-components/layout';
import { H3, Text } from '@amzn/stencil-react-components/text';
import FaqErcContacts from 'src/constants/faq-erc-contacts';
import mapValues from 'lodash.mapvalues';

export default function ERCFaqComponent() {

    const { t } = useTranslation();

    return (
        <>
            <Col gridGap="S200">
                <View>
                    <H3 color="primary70">{t(TranslationKeys.FAQ.FAQ_ERC_HEADER)}</H3>
                </View>
                <Text><strong>{t(TranslationKeys.FAQ.FAQ_ERC_QUESTION1)}</strong></Text>

                {Object.keys(FaqErcContacts.PLACEHOLDER_TO_COUNTRY_MAP).map((index?: any) => (
                    <div key={`password-question-${index}`}>
                        <Text>{t(TranslationKeys.FAQ[`FAQ_ERC_ANSWER1_${index}`])}</Text>
                        <Text>{t(TranslationKeys.FAQ[`FAQ_ERC_ANSWER1_${index}_CONTACT`],
                            mapValues(FaqErcContacts.PLACEHOLDER_TO_COUNTRY_MAP[index],
                                countryKey => FaqErcContacts.CONTACTS[countryKey]))}</Text>
                    </div>
                ))}
                <View>
                    <Text>{t(TranslationKeys.FAQ.FAQ_ERC_ANSWER1_INDIA)}</Text>
                    <Text>{t(TranslationKeys.FAQ.FAQ_ERC_ANSWER1_INDIA_CONTACT)}</Text>
                </View>
            </Col>
        </>
    )
};
