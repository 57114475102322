import { OtpData } from "src/redux/pre-hire/otp-slice";
import { WelcomeInfoState } from "src/redux/pre-hire/welcome-info-slice";

const sessionKey = 'com.amazon.work.session';

export const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem(sessionKey);
        if (serializedState) {
            return JSON.parse(serializedState);
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
}

export const saveState = (state: { welcomeInfo: WelcomeInfoState; otp: OtpData; }) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem(sessionKey, serializedState);
    } catch (err) {
        // Ignore write errors
    }
}
