export enum IngressPoint {
    ASH ="ASH",
    EMAIL = "EMAIL",
    SMS = "SMS"
}

export default interface PreHireWelcomeResponse {
    ingress: IngressPoint,
    login: string,
    preferredName: string
}
