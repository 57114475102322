import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface OtpData {
    selectedOtpIndex: number,
    selectedOtp: string,
    enteredOtp: string,
    otpLengthCheck?: boolean,
}

export const initialState: OtpData = {
    selectedOtpIndex: -1,
    selectedOtp: '',
    enteredOtp: ''
}

const otpSlice = createSlice({
    name: 'otp',
    initialState,
    reducers: {
        setSelectedOtpIndex: (state: OtpData, action: PayloadAction<number>) => {
            state.selectedOtpIndex = action.payload
        },
        setSelectedOtp: (state: OtpData, action: PayloadAction<string>) => {
            state.selectedOtp = action.payload
        },
        setEnteredOtp: (state: OtpData, action: PayloadAction<string>) => {
            state.enteredOtp = action.payload
        },
        setOtpLengthCheck: (state: OtpData, action: PayloadAction<boolean>) => {
            state.otpLengthCheck = action.payload
        },
        // clear the otp input data
        resetEnteredOtpData: (state: OtpData) => {
            state.enteredOtp = initialState.enteredOtp,
            state.otpLengthCheck = false
        }
    }
});

export const { setSelectedOtpIndex, setSelectedOtp, setEnteredOtp, setOtpLengthCheck, resetEnteredOtpData } = otpSlice.actions;

export default otpSlice.reducer;
