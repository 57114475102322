import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface PasswordMetaData {
    enteredPassword: string,
    enteredPasswordAgain: string,
    charLimitCheck?: boolean,
    specialCharCheck?: boolean,
    passwordMatch?: boolean,
}

export const initialState: PasswordMetaData = {
    enteredPassword: "",
    enteredPasswordAgain: "",
    charLimitCheck: false,
    specialCharCheck: false,
    passwordMatch: false
}

const passwordSlice = createSlice({
    name: 'password',
    initialState,
    reducers: {
        setEnteredPassword: (state: PasswordMetaData, action: PayloadAction<string>) => {
            state.enteredPassword = action.payload
        },
        setEnteredPasswordAgain: (state: PasswordMetaData, action: PayloadAction<string>) => {
            state.enteredPasswordAgain = action.payload
        },
        setCharLimitCheck: (state: PasswordMetaData, action: PayloadAction<boolean>) => {
            state.charLimitCheck = action.payload
        },
        setSpecialCharCheck: (state: PasswordMetaData, action: PayloadAction<boolean>) => {
            state.specialCharCheck = action.payload
        },
        setPasswordMatch: (state: PasswordMetaData, action: PayloadAction<boolean>) => {
            state.passwordMatch = action.payload
        },
        resetPasswordStates: () => initialState
    }
});

export const { setEnteredPassword, setEnteredPasswordAgain, setCharLimitCheck, setSpecialCharCheck, setPasswordMatch, resetPasswordStates } = passwordSlice.actions;

export default passwordSlice.reducer;
