class StringUtil {

    public static isEmpty(text: string | undefined) : boolean {
        if (text && text.length > 0) {
            return false;
        }
        return true;
    }

}

export default StringUtil;
