import React from "react";
import { useTranslation } from "react-i18next";
import FaqErcContacts from "src/constants/faq-erc-contacts";
import TranslationKeys from "src/constants/translation-keys";
import { mapValues } from "lodash";

export default function FaqModal() {
    const { t } = useTranslation();

    return (
        <div className="modal justfade" id="faqModal" tabIndex={-1} role="dialog" aria-labelledby="faqModal_label">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <a href="javascript:void(0)" data-dismiss="modal" className="pull-right">
                  <div className="close-icon" data-dismiss="modal"></div>
                </a>
                <h2 className="modal-title" id="faqModal_Label"> {t(TranslationKeys.FAQ.FAQ_TITLE)} </h2>
              </div>
              <div className="modal-body">
                <p className="section-header">{t(TranslationKeys.FAQ.FAQ_LOGIN_HEADER)}</p>
                <p className="question">{t(TranslationKeys.FAQ.FAQ_LOGIN_QUESTION)}</p>
                <p className="answer">
                  {t(TranslationKeys.FAQ.FAQ_LOGIN_ANSWER)}
                </p>

                <p className="section-header">{t(TranslationKeys.FAQ.FAQ_PHONE_HEADER)}</p>
                <p className="question">{t(TranslationKeys.FAQ.FAQ_PHONE_QUESTION2)}</p>
                <p className="answer">
                  {t(TranslationKeys.FAQ.FAQ_PHONE_ANSWER2)}
                </p>
                <p className="question">{t(TranslationKeys.FAQ.FAQ_PHONE_QUESTION3)}</p>
                <p className="answer">
                  {t(TranslationKeys.FAQ.FAQ_PHONE_ANSWER3)}
                </p>
                <div id="faqCode">
                  <p className="section-header">{t(TranslationKeys.FAQ.FAQ_OTP_HEADER)}</p>
                  <p className="question">{t(TranslationKeys.FAQ.FAQ_OTP_QUESTION1)}</p>
                  <div className="answer">
                    <p>
                      {t(TranslationKeys.FAQ.FAQ_OTP_ANSWER1_PART0)}
                    </p>
                    <p>
                      {t(TranslationKeys.FAQ.FAQ_OTP_ANSWER1_PART1)}
                    </p>
                    <p>
                      {t(TranslationKeys.FAQ.FAQ_OTP_ANSWER1_PART2)}
                    </p>
                    <p>
                      {t(TranslationKeys.FAQ.FAQ_OTP_ANSWER1_PART3)}
                    </p>
                    <p>
                      {t(TranslationKeys.FAQ.FAQ_OTP_ANSWER1_PART4)}
                    </p>
                  </div>
                  <p className="question">{t(TranslationKeys.FAQ.FAQ_OTP_QUESTION3)}</p>
                  <p className="answer">
                    {t(TranslationKeys.FAQ.FAQ_OTP_ANSWER3)}
                  </p>
                  <p className="question">{t(TranslationKeys.FAQ.FAQ_OTP_QUESTION4)}</p>
                  <p className="answer">
                    {t(TranslationKeys.FAQ.FAQ_OTP_ANSWER4)}
                  </p>
                  <p className="question">{t(TranslationKeys.FAQ.FAQ_OTP_QUESTION5, { '0': 'Amazon A to Z' })}</p>
                  <p className="answer">
                    {t(TranslationKeys.FAQ.FAQ_OTP_ANSWER5)}
                  </p>
                </div>

                <div id="faqPassword">
                  <p className="section-header">{t(TranslationKeys.FAQ.FAQ_PASSWORD_HEADER)}</p>
                  <p className="question">{t(TranslationKeys.FAQ.FAQ_PASSWORD_QUESTION1)}</p>
                  <p className="answer">
                    {t(TranslationKeys.FAQ.FAQ_PASSWORD_ANSWER1)}
                  </p>
                  <p className="question">{t(TranslationKeys.FAQ.FAQ_PASSWORD_QUESTION2)}</p>
                  <p className="answer">
                    {t(TranslationKeys.FAQ.FAQ_PASSWORD_ANSWER2)}
                  </p>
                  <p className="question">{t(TranslationKeys.FAQ.FAQ_PASSWORD_QUESTION3)}</p>
                  <p className="answer">
                    {t(TranslationKeys.FAQ.FAQ_PASSWORD_ANSWER3)}
                  </p>
                  <p className="question">{t(TranslationKeys.FAQ.FAQ_PASSWORD_QUESTION4)}</p>
                  <p className="answer">
                    {t(TranslationKeys.FAQ.FAQ_PASSWORD_ANSWER4)}
                  </p>
                  <p className="question">{t(TranslationKeys.FAQ.FAQ_PASSWORD_QUESTION5)}</p>
                  <p className="answer">
                    {t(TranslationKeys.FAQ.FAQ_PASSWORD_ANSWER5)}
                  </p>
                  <p className="question">{t(TranslationKeys.FAQ.FAQ_PASSWORD_QUESTION6)}</p>
                  <p className="answer">
                    {t(TranslationKeys.FAQ.FAQ_PASSWORD_ANSWER6)}
                  </p>
                  <p className="question">{t(TranslationKeys.FAQ.FAQ_PASSWORD_QUESTION7)}</p>
                  <p className="answer">
                    {t(TranslationKeys.FAQ.FAQ_PASSWORD_ANSWER7)}
                  </p>
                  <p className="question">{t(TranslationKeys.FAQ.FAQ_PASSWORD_QUESTION8)}</p>
                  <p className="answer">
                    {t(TranslationKeys.FAQ.FAQ_PASSWORD_ANSWER8)}
                  </p>
                </div>

                <div id="faqERC">
                  <p className="section-header">{t(TranslationKeys.FAQ.FAQ_ERC_HEADER)}</p>
                  <p className="question">{t(TranslationKeys.FAQ.FAQ_ERC_QUESTION1)}</p>
                  <p className="answer">
                    {Object.keys(FaqErcContacts.PLACEHOLDER_TO_COUNTRY_MAP).map((country: any, index: number) => (
                      <React.Fragment key={country}>
                        {t(TranslationKeys.FAQ[`FAQ_ERC_ANSWER1_${country}`])}
                        <br />
                        {t(
                            TranslationKeys.FAQ[`FAQ_ERC_ANSWER1_${country}_CONTACT`],
                            mapValues(
                              FaqErcContacts.PLACEHOLDER_TO_COUNTRY_MAP[country],
                              countryKey => FaqErcContacts.CONTACTS[countryKey]
                            )
                        )}
                        {index < Object.keys(FaqErcContacts.PLACEHOLDER_TO_COUNTRY_MAP).length - 1 &&
                          <>
                            <br />
                            <br />
                          </>
                        }
                      </React.Fragment>
                    ))}
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
    );
}
