import { Row } from "@amzn/stencil-react-components/layout";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Text } from "@amzn/stencil-react-components/text";
import TranslationKeys from "src/constants/translation-keys";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { setPasswordApiError, setResetPinApiError, setSubmitted } from "src/redux/pre-hire/create-password-slice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import SetPreHirePasswordRequest from "src/@types/api/SetPreHirePasswordRequest";
import APIHelper from "src/helpers/api-helper";
import { useNavigate } from "react-router";
import ReactRoutes from "src/constants/react-routes";
import ApiError from "src/errors/api-error";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import StringUtil from 'src/util/StringUtil';
import { getLogger } from "src/logger/logger";
import { COMPONENT_EVENT, COMPONENT_EVENT_RESULT } from "src/logger/loggerConstants";

export default function PasswordSubmissionComponent() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const {
        enteredPassword,
        charLimitCheck,
        passwordMatch,
        specialCharCheck
    } = useAppSelector(state => state.passwordInput);
    let { login, passwordEventId } = useAppSelector(state => state.welcomeInfo);
    const {otpLengthCheck, enteredOtp} = useAppSelector(state => state.otp);
    const featureGates = useAppSelector(state => state.contexts.context?.reactScreenActiveFeatureGates);
    const [rendering, setRendering] = useState(false);
    const logger = getLogger();

    function handleOnClick() {
        setRendering(true);
        // Set password API call iff all dynamic checks passes
        if (charLimitCheck && specialCharCheck && passwordMatch && otpLengthCheck) {
            if (!StringUtil.isEmpty(login) && !StringUtil.isEmpty(passwordEventId)) {
                let request: SetPreHirePasswordRequest = {
                    login: login,
                    eventGuid: passwordEventId,
                    password: enteredPassword,
                    resetToken: enteredOtp,
                    language: localStorage.getItem('i18nextLng') as string
                }
                APIHelper.setPasswordWithSIF(request, featureGates)
                    .then(response => {
                        setRendering(false);
                        navigate(ReactRoutes.PREHIRES_DOWNLOAD_APP);
                    }).catch(error => {
                        setRendering(false);
                        if (error instanceof ApiError) {
                            switch (error.statusCode) {
                                // Password doesn't meet rules
                                case 400: dispatch(setPasswordApiError(t(TranslationKeys.UI.PRE_HIRES.PASSWORD_CREATION_GLOBAL_ERROR2))); break;
                                // Invalid Reset token
                                case 503:  dispatch(setResetPinApiError(t(TranslationKeys.UI.PRE_HIRES.OTP_INVALID_ENTRY))); break;
                                // TODO: handle statusCode 409(Session expired) explicitly
                                default: dispatch(setPasswordApiError(t(TranslationKeys.UI.PRE_HIRES.GENERAL_FALLBACK_ERROR)));
                                logger.error(`${COMPONENT_EVENT.PREHIRE_PASSWORD_PAGE.CREATE_PASSWORD_BUTTON} ${COMPONENT_EVENT_RESULT.FAILED}`, error, { username:login });
                            }
                        }
                    })
            }
        } else {
            setRendering(false);
        }
        dispatch(setSubmitted(true));
    }

    function handleBackButtonClick() {
        navigate(ReactRoutes.PREHIRES_OTP_DESTINATION);
    }

    return (
        <div>
            {rendering ? <Spinner fr={""} size={SpinnerSize.Medium}/> :
                <Row gridGap="S300" alignItems={"start"}>
                    <Button data-testid="back-button"
                            style={{width: '50%', height: "0%"}}
                            onClick={() => {logger.info(`${COMPONENT_EVENT.PREHIRE_PASSWORD_PAGE.BACK_BUTTON}`, { username:login });
                            handleBackButtonClick()}}>
                        <Text fontSize={"T200"} fontWeight={"medium"}>
                            {t(TranslationKeys.UI.BUTTONS.BACK)}
                        </Text>
                    </Button>
                    <Button data-testid="create-password-button"
                            variant={ButtonVariant.Primary}
                            style={{width: '50%', height: '0%'}}
                            onClick={() => {logger.info(`${COMPONENT_EVENT.PREHIRE_PASSWORD_PAGE.CREATE_PASSWORD_BUTTON}`, { username:login });
                            handleOnClick()}}>
                        <Text fontSize={"T200"} fontWeight={"medium"}>
                            {t(TranslationKeys.UI.BUTTONS.CREATE_PASSWORD)}
                        </Text>
                    </Button>
                </Row>}
        </div>);
}
