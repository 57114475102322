import React, { useEffect } from 'react';
import Footer from './Footer';
import EnterTokenAndPasswordContentView from './EnterTokenAndPasswordContentView';
import { getLogger } from "src/logger/logger";
import { METRIC_NAME } from "src/logger/loggerTypes";
import { COMPONENT_EVENT } from "src/logger/loggerConstants";
import { useAppSelector } from "src/redux/hooks";

function EnterOtpScreen(){
    const login: string = useAppSelector(state => state.welcomeInfo.login);
    const logger = getLogger();
    useEffect(() => {
    logger.info(`${COMPONENT_EVENT.PREHIRE_PASSWORD_PAGE.SCREEN_LOADING}`, { username: login })
    }, []);
    return (
        <div className="fullScreen">
            <div className="row-flex-container">
                <div data-testid="createPasswordImageBackground" className="flex-box-left-lock-background">
                </div>
                <div className="flex-box-right">
                    <div data-testid="createPasswordContent" className="content-wrap">
                        <EnterTokenAndPasswordContentView/>
                    </div>
                    <div data-testid="footer" className="footer-container">
                        <Footer/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EnterOtpScreen;